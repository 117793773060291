import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import { newsLetterStart } from "../../../store/slices/HomeSlice";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import * as Yup from "yup";

const FooterIndex = () => {

  const disptach = useDispatch();
  const location = useLocation();
  const settingsJson = useSelector((state) => state.home.settingsJson);
  const newsLetter = useSelector((state) => state.home.newsLetter);
  const [newsSave, setNewsSave] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [deviceToken, setDeviceToken] = useState("");
  const [deviceUniqueId, setDeviceUniqueId] = useState("");

  useEffect(() => {
    getDeviceUniqueID();
  }, []);

  async function getDeviceUniqueID() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    setDeviceUniqueId(result.visitorId);
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required("Email required"),
  });

  const handleSubmit = (values) => {
    disptach(
      newsLetterStart({
        email_address: values.email,
        device_token: deviceToken,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !newsLetter.loading &&
      Object.keys(newsLetter.data).length > 0
    )
      setNewsSave(true);
    setSkipRender(false);
  }, [newsLetter]);

  return (
    <>
      <footer className="footer-sec">
        {!location.pathname.includes("/unsubscribe") ? <div className="news-letter-sec">
          <Container>
            <Row className="justify-content-md-center">
              <Col md={8} xl={6} lg={6}>
                <div className="news-letter-card">
                  <h4>Newsletter</h4> 
                  {!newsSave ? (
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      onSubmit={handleSubmit}
                      validationSchema={validationSchema}
                    >
                      {({ values, setFieldValue, resetForm }) => (
                        <FORM className="news-letter-form">
                          <InputGroup className="mb-0">
                            <Form.Control
                              type="email"
                              name="email"
                              placeholder="Your email"
                              onChange={(e) =>
                                setFieldValue("email", e.target.value)
                              }
                            />
                            <InputGroup.Text id="basic-addon2">
                              <Button
                                disabled={newsLetter.buttonDisable}
                                className="send-btn"
                                type="submit"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 18 18"
                                >
                                  <path
                                    fill="#fff"
                                    d="M.487 8.727c-.673.353-.645.87.06 1.149l2.09.827c.706.28 1.775.127 2.373-.34l9.075-7.157c.597-.469.66-.4.143.155l-7.175 7.68c-.52.552-.366 1.231.342 1.505l.245.096c.708.273 1.863.733 2.566 1.02l2.318.943c.704.286 1.457-.05 1.657-.784L17.713.837c.2-.733-.188-1.044-.86-.692L.487 8.727zM6.222 17.228c-.04.124 1.43-2.13 1.43-2.13.415-.635.18-1.392-.523-1.68l-1.604-.656c-.702-.287-1.039.05-.748.752 0 0 1.488 3.586 1.445 3.714z"
                                  ></path>
                                </svg>
                              </Button>
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                              component={"div"}
                              name="email"
                              className="errorMsg"
                            />
                        </FORM>
                      )}
                    </Formik>
                  ) : (
                    <p>
                      Thanks for subscribing with us. Will send the updates to
                      your email
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div> : null}
        <div className="footer-link-sec">
          <Container>
            {!settingsJson.loading &&
              Object.keys(settingsJson.data).length > 0 && (
                <div className="footer-link-box">
                  <div className="footer-link-card">
                    <ul className="footer-social-link-list list-unstyled">
                      {settingsJson.data.data.facebook_link && (
                        <li>
                          <a
                            href={settingsJson.data.data.facebook_link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Image
                              className="footer-social-link-icon"
                              src={
                                window.location.origin +
                                "/assets/img/footer/facebook.svg"
                              }
                            />
                          </a>
                        </li>
                      )}
                      {settingsJson.data.data.instagram_link && (
                        <li>
                          <a
                            href={settingsJson.data.data.instagram_link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Image
                              className="footer-social-link-icon"
                              src={
                                window.location.origin +
                                "/assets/img/footer/instagram.svg"
                              }
                            />
                          </a>
                        </li>
                      )}
                      {settingsJson.data.data.twitter_link && (
                        <li>
                          <a
                            href={settingsJson.data.data.twitter_link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Image
                              className="footer-social-link-icon"
                              src={
                                window.location.origin +
                                "/assets/img/footer/twitter.svg"
                              }
                            />
                          </a>
                        </li>
                      )}
                      {settingsJson.data.data.pinterest_link && (
                        <li>
                          <a
                            href={settingsJson.data.data.pinterest_link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Image
                              className="footer-social-link-icon"
                              src={
                                window.location.origin +
                                "/assets/img/footer/pinterest.svg"
                              }
                            />
                          </a>
                        </li>
                      )}
                      {settingsJson.data.data.linkedin_link && (
                        <li>
                          <a
                            href={settingsJson.data.data.linkedin_link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Image
                              className="footer-social-link-icon"
                              src={
                                window.location.origin +
                                "/assets/img/footer/linkedin.svg"
                              }
                            />
                          </a>
                        </li>
                      )}
                      {settingsJson.data.data.telegram_link && (
                        <li>
                          <a
                            href={settingsJson.data.data.telegram_link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Image
                              className="footer-social-link-icon"
                              src={
                                window.location.origin +
                                "/assets/img/footer/telegram.svg"
                              }
                            />
                          </a>
                        </li>
                      )}
                      {settingsJson.data.data.spotify_link && (
                        <li>
                          <a
                            href={settingsJson.data.data.spotify_link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Image
                              className="footer-social-link-icon"
                              src={
                                window.location.origin +
                                "/assets/img/footer/spotify.svg"
                              }
                            />
                          </a>
                        </li>
                      )}
                      {settingsJson.data.data.apple_music_link && (
                        <li>
                          <a
                            href={settingsJson.data.data.apple_music_link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Image
                              className="footer-social-link-icon"
                              src={
                                window.location.origin +
                                "/assets/img/footer/apple-music.svg"
                              }
                            />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="footer-link-card">
                    <ul className="list-unstyled footer-link-list">
                      <li>
                        <a
                          target="_blank"
                          href={`${window.location.origin}/page/privacy`}
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href={`${window.location.origin}/page/terms`}
                          rel="noreferrer"
                        >
                          Terms and Conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-link-card">
                    <ul className="list-unstyled footer-apps-link-list">
                      {settingsJson.data.data.appstore_link && (
                        <li>
                          <a
                            href={settingsJson.data.data.appstore_link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Image
                              className="footer-app-link-icon"
                              src={
                                window.location.origin +
                                "/assets/img/footer/app-store.svg"
                              }
                            />
                          </a>
                        </li>
                      )}
                      {settingsJson.data.data.playstore_link && (
                        <li>
                          <a
                            href={settingsJson.data.data.playstore_link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Image
                              className="footer-app-link-icon"
                              src={
                                window.location.origin +
                                "/assets/img/footer/play-store.svg"
                              }
                            />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
          </Container>
        </div>
        <div className="footer-copyrights-sec">
          <Container>
            {!settingsJson.loading &&
              Object.keys(settingsJson.data).length > 0 && (
                <Row className="justify-content-md-center">
                  <Col md={5}>
                    <div className="copyrights-card">
                      {settingsJson.data.data.copyright_content}
                    </div>
                  </Col>
                </Row>
              )}
          </Container>
        </div>
      </footer>
    </>
  );
};

export default FooterIndex;
