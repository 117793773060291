import React, { useEffect, useState } from "react";
import { Container, } from "react-bootstrap";
import NewsCategoriesListIndex from "./NewsCatgeoriesListIndex";
import NewsListIndex from "./NewsListIndex";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCategory } from "../../store/slices/HomeSlice";

const LandingPageIndex = (props) => {

  const params = useParams();
  const navigate = useNavigate();
  const selectedCategory = useSelector((state) => state.home.selectedCategory);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(()=> {
    selectedCategory.label && selectedCategory.label != "All" && navigate(`/feed/${selectedCategory.label.toLowerCase()}`)
  }, [selectedCategory])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div className="news-landing-page-sec">
        <NewsCategoriesListIndex />
        <NewsListIndex params={params} />
      </div>
      {showScrollButton && (
        <button className="scroll-up-button" onClick={scrollToTop}>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="none"
            viewBox="0 0 50 50"
          >
            <g clipPath="url(#clip0_1029_15)">
              <path
                fill="#9747FF"
                d="M48.218 25A23.214 23.214 0 111.79 25a23.214 23.214 0 0146.428 0z"
              ></path>
              <path
                fill="#A560FF"
                d="M14.921 4.123A23.214 23.214 0 001.79 25a23.215 23.215 0 0023.214 23.214 23.214 23.214 0 0017.225-7.694 23.212 23.212 0 01-10.082 2.337A23.214 23.214 0 018.932 19.643a23.214 23.214 0 015.99-15.52z"
              ></path>
              <path
                fill="#131615"
                d="M25 0C11.214 0 0 11.214 0 25a24.947 24.947 0 007.114 17.47 1.785 1.785 0 003.064-1.228 1.785 1.785 0 00-.507-1.269A21.336 21.336 0 013.571 25C3.571 13.143 13.143 3.571 25 3.571S46.429 13.143 46.429 25 36.857 46.429 25 46.429a21.32 21.32 0 01-10.054-2.5 1.786 1.786 0 00-1.678 3.151A24.91 24.91 0 0025 50c13.786 0 25-11.214 25-25S38.786 0 25 0z"
              ></path>
              <path
                fill="#fff"
                d="M23.735 11.282l-8.929 8.929a1.785 1.785 0 002.525 2.525l5.88-5.879v20.69a1.786 1.786 0 003.572 0v-20.69l5.88 5.88a1.786 1.786 0 002.525-2.524l-8.929-8.929c-.698-.705-1.9-.664-2.524-.002z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_1029_15">
                <path fill="#fff" d="M0 0H50V50H0z"></path>
              </clipPath>
            </defs>
          </svg> */}
           <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="none"
              viewBox="0 0 448 448"
            >
              <path
                fill="#000"
                d="M224 0a224 224 0 100 448 224 224 0 000-448zm0 416a192 192 0 11.001-384A192 192 0 01224 416z"
              ></path>
              <path
                fill="#000"
                d="M235.363 164.64a15.983 15.983 0 00-5.207-3.502 15.988 15.988 0 00-12.306 0 15.983 15.983 0 00-5.207 3.502l-48 48a16.064 16.064 0 0022.72 22.72l20.64-20.8V336a16.003 16.003 0 0027.314 11.314c3-3.001 4.686-7.071 4.686-11.314V214.56l20.64 20.8a15.983 15.983 0 005.207 3.502 15.984 15.984 0 0012.306 0 15.983 15.983 0 005.207-3.502 15.983 15.983 0 003.502-5.207 15.988 15.988 0 000-12.306 15.983 15.983 0 00-3.502-5.207l-48-48zM272.003 96h-96a16.001 16.001 0 00-11.314 27.314c3.001 3 7.07 4.686 11.314 4.686h96c4.243 0 8.313-1.686 11.314-4.686a16.004 16.004 0 000-22.628A16.003 16.003 0 00272.003 96z"
              ></path>
            </svg>
        </button>
      )}
    </div>
  );
};

export default LandingPageIndex;

