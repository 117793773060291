import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import useSummary from "../../hooks/useSummary";
import Skeleton from "react-loading-skeleton";

const CustomizeIndex = (props) => {

  const { handleSummarize } = useSummary();
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState(null);

  const validationSchema = Yup.object().shape({
    max_length: Yup.string().required("length required"),
    url: Yup.string()
      .required("url required") 
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    let res = await handleSummarize(values);
    if (res.status_code != 500) {
      setNews(res.response);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="customize-page-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <div className="customize-form-box">
                <Formik
                  initialValues={{
                    url: "",
                    max_length: 500,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, values, isValid }) => (
                    <FORM className="customize-form-sec">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Web URL</Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Enter URL"
                          name="url"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="url"
                          className="errorMsg"
                        />
                      </Form.Group>
                      {/* <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Max Length</Form.Label>
                        <Field
                          className="form-control"
                          type="number"
                          placeholder="Enter max length"
                          name="max_length"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="max_length"
                          className="errorMsg"
                        />
                      </Form.Group> */}
                      {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Select Publisher</Form.Label>
                        <Dropdown
                          // placeholder="More"
                          className="my-className"
                          options={data}
                        />
                      </Form.Group> */}
                      {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email URL</Form.Label>
                        <Form.Control type="email" placeholder="Enter the required words count" />
                      </Form.Group> */}
                      <div className="customize-form-submit-btn">
                        <Button className="default-btn" type="submit">
                          Submit
                        </Button>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-md-center">
            <Col md={12} xl={12} lg={12}>
              <div className="customize-body-sec">
                <div className="customize-box">
                  <div className="customize-link">
                    {loading ? (
                      [...Array(1)].map((i, key) => (
                        <div className="news-list-body-sec" key={key}>
                          <div className="news-list-box">
                            <div className="news-list-link">
                              <div className="news-list-card">
                                <div className="news-list-left-sec">
                                  <div className="news-list-img-sec-1">
                                    <Skeleton width="100%" height={250} />
                                  </div>
                                </div>
                                <div className="news-list-right-sec">
                                  <div className="news-list-content-sec">
                                    <div className="news-info-header-sec">
                                      <Skeleton height={20} className="mb-3" />
                                    </div>
                                    <div className="news-info-body-sec">
                                      <h4>
                                        <Skeleton count={2} height={30} />
                                      </h4>
                                      <div className="news-info-publish-date-sec">
                                        <Skeleton width={80} height={30} />
                                      </div>
                                      <div className="news-info-desc">
                                        <Skeleton count={5} height={10} />
                                      </div>
                                      <Skeleton
                                        width={120}
                                        height={40}
                                        className="mt-3"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : news ? (
                      <div className="customize-card">
                        <div className="customize-left-sec">
                          <div className="customize-img-sec">
                            <Image
                              className="customize-img"
                              src={
                                window.location.origin +
                                "/assets/img/new-img-1.png"
                              }
                            />
                          </div>
                          <Button className="customize-download-icon-sec">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="none"
                              viewBox="0 0 21 21"
                            >
                              <path
                                stroke="url(#paint0_linear_181_1114)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="M6.333 10L11 14.232l4.667-4.191M11 3v11m-7 3h14"
                              ></path>
                              <defs>
                                <linearGradient
                                  id="paint0_linear_181_1114"
                                  x1="4.911"
                                  x2="18.316"
                                  y1="6.945"
                                  y2="7.663"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#9747FF"></stop>
                                  <stop offset="1" stopColor="#2F50FE"></stop>
                                </linearGradient>
                              </defs>
                            </svg>
                          </Button>
                          <Button className="customize-copy-url-icon-sec">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="none"
                              viewBox="0 0 17 17"
                            >
                              <path
                                fill="url(#paint0_linear_193_1127)"
                                d="M9.7 14.167a3.484 3.484 0 002.474-1.039 3.567 3.567 0 001.026-2.503V4.422a2.843 2.843 0 00-.82-2.004L10.81.83A2.78 2.78 0 008.83 0H5.5a3.483 3.483 0 00-2.474 1.039A3.567 3.567 0 002 3.542v7.083c.001.939.37 1.84 1.026 2.503A3.484 3.484 0 005.5 14.167h4.2zm-6.3-3.542V3.542c0-.564.221-1.104.615-1.503A2.088 2.088 0 015.5 1.417s3.443.01 3.5.017v1.4c0 .375.148.735.41 1.001.263.266.619.415.99.415h1.383c.007.057.017 6.375.017 6.375 0 .564-.221 1.104-.615 1.503a2.087 2.087 0 01-1.485.622H5.5a2.088 2.088 0 01-1.485-.622 2.138 2.138 0 01-.615-1.503zM16 5.667v7.791a3.567 3.567 0 01-1.026 2.503A3.483 3.483 0 0112.5 17H6.2a.696.696 0 01-.495-.207.713.713 0 010-1.002.696.696 0 01.495-.208h6.3c.557 0 1.091-.223 1.485-.622.394-.399.615-.94.615-1.503V5.667c0-.188.074-.368.205-.501a.696.696 0 01.99 0 .712.712 0 01.205.5z"
                              ></path>
                              <defs>
                                <linearGradient
                                  id="paint0_linear_193_1127"
                                  x1="2.911"
                                  x2="16.328"
                                  y1="4.791"
                                  y2="5.383"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#9747FF"></stop>
                                  <stop offset="1" stopColor="#2F50FE"></stop>
                                </linearGradient>
                              </defs>
                            </svg>
                          </Button>
                        </div>
                        <div className="customize-right-sec">
                          <div className="customize-content-sec">
                            <div className="customize-body-sec">
                              <h5>Times of India</h5>
                              <h4>
                                India will be a major global player in science
                                by next decade, a lot will be driven by AI:
                                Experts predict science advancements.
                                <Button className="customize-copy-icon-sec">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="none"
                                    viewBox="0 0 17 17"
                                  >
                                    <path
                                      fill="url(#paint0_linear_193_1127)"
                                      d="M9.7 14.167a3.484 3.484 0 002.474-1.039 3.567 3.567 0 001.026-2.503V4.422a2.843 2.843 0 00-.82-2.004L10.81.83A2.78 2.78 0 008.83 0H5.5a3.483 3.483 0 00-2.474 1.039A3.567 3.567 0 002 3.542v7.083c.001.939.37 1.84 1.026 2.503A3.484 3.484 0 005.5 14.167h4.2zm-6.3-3.542V3.542c0-.564.221-1.104.615-1.503A2.088 2.088 0 015.5 1.417s3.443.01 3.5.017v1.4c0 .375.148.735.41 1.001.263.266.619.415.99.415h1.383c.007.057.017 6.375.017 6.375 0 .564-.221 1.104-.615 1.503a2.087 2.087 0 01-1.485.622H5.5a2.088 2.088 0 01-1.485-.622 2.138 2.138 0 01-.615-1.503zM16 5.667v7.791a3.567 3.567 0 01-1.026 2.503A3.483 3.483 0 0112.5 17H6.2a.696.696 0 01-.495-.207.713.713 0 010-1.002.696.696 0 01.495-.208h6.3c.557 0 1.091-.223 1.485-.622.394-.399.615-.94.615-1.503V5.667c0-.188.074-.368.205-.501a.696.696 0 01.99 0 .712.712 0 01.205.5z"
                                    ></path>
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_193_1127"
                                        x1="2.911"
                                        x2="16.328"
                                        y1="4.791"
                                        y2="5.383"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#9747FF"></stop>
                                        <stop
                                          offset="1"
                                          stopColor="#2F50FE"
                                        ></stop>
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                </Button>
                              </h4>
                              <div className="customize-desc">
                                <p dangerouslySetInnerHTML={{
                                    __html: news,
                                  }}>
                                  <Button className="customize-copy-icon-sec">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="25"
                                      fill="none"
                                      viewBox="0 0 17 17"
                                    >
                                      <path
                                        fill="url(#paint0_linear_193_1127)"
                                        d="M9.7 14.167a3.484 3.484 0 002.474-1.039 3.567 3.567 0 001.026-2.503V4.422a2.843 2.843 0 00-.82-2.004L10.81.83A2.78 2.78 0 008.83 0H5.5a3.483 3.483 0 00-2.474 1.039A3.567 3.567 0 002 3.542v7.083c.001.939.37 1.84 1.026 2.503A3.484 3.484 0 005.5 14.167h4.2zm-6.3-3.542V3.542c0-.564.221-1.104.615-1.503A2.088 2.088 0 015.5 1.417s3.443.01 3.5.017v1.4c0 .375.148.735.41 1.001.263.266.619.415.99.415h1.383c.007.057.017 6.375.017 6.375 0 .564-.221 1.104-.615 1.503a2.087 2.087 0 01-1.485.622H5.5a2.088 2.088 0 01-1.485-.622 2.138 2.138 0 01-.615-1.503zM16 5.667v7.791a3.567 3.567 0 01-1.026 2.503A3.483 3.483 0 0112.5 17H6.2a.696.696 0 01-.495-.207.713.713 0 010-1.002.696.696 0 01.495-.208h6.3c.557 0 1.091-.223 1.485-.622.394-.399.615-.94.615-1.503V5.667c0-.188.074-.368.205-.501a.696.696 0 01.99 0 .712.712 0 01.205.5z"
                                      ></path>
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_193_1127"
                                          x1="2.911"
                                          x2="16.328"
                                          y1="4.791"
                                          y2="5.383"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stopColor="#9747FF"></stop>
                                          <stop
                                            offset="1"
                                            stopColor="#2F50FE"
                                          ></stop>
                                        </linearGradient>
                                      </defs>
                                    </svg>
                                  </Button>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CustomizeIndex;
