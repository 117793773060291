import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import { bookmarkSaveFailure, bookmarkSaveSuccess, bookmarksFeedFailure, bookmarksFeedSuccess, fetchStaticPageFailure, fetchStaticPageSuccess, homeFeedFailure, homeFeedSuccess, newsLetterFailure, newsLetterSuccess, settingsJsonFailure, settingsJsonSuccess, singleNewsFailure, singleNewsSuccess, unSubscribeFailure, unSubscribeSuccess } from "../slices/HomeSlice";

function* settingsJsonAPI(action) {
  yield getCommonSaga({
    apiUrl: "get_settings_json",
    payload: action.payload,
    successNotify: false,
    success: settingsJsonSuccess,
    failure: settingsJsonFailure,
  });
}

function* homeFeedAPI(action) {
  const homeFeedData = yield select(state => state.home.homeFeed.data);
  yield getCommonSaga({
    apiUrl: "home",
    payload: action.payload,
    successCallback: true,
    success: data => {
      if (Object.keys(homeFeedData).length > 0)
      {
        store.dispatch(homeFeedSuccess({
          ...homeFeedData,
          news: [...homeFeedData.news, ...data.news],
          total_news: homeFeedData.total_news,
        }));
      }
      else
      store.dispatch(homeFeedSuccess(data));
    },
    failure: homeFeedFailure,
  });
}

function* bookmarksFeedAPI(action) {
  const bookmarksFeedData = yield select(state => state.home.bookmarksFeed.data);
  yield getCommonSaga({
    apiUrl: "bookmarks_list",
    payload: action.payload,
    successCallback: true,
    success: data => {
      if (Object.keys(bookmarksFeedData).length > 0)
      {
        store.dispatch(bookmarksFeedSuccess({
          ...bookmarksFeedData,
          news: [...bookmarksFeedData.news, ...data.news],
          total_news: bookmarksFeedData.total_news,
        }));
      }
      else
      store.dispatch(bookmarksFeedSuccess(data));
    },
    failure: bookmarksFeedFailure,
  });
}

function* bookmarkSaveAPI(action) {
  const homeFeedData = yield select(state => state.home.homeFeed.data);
  const bookmarksFeedData = yield select(state => state.home.bookmarksFeed.data);
  yield getCommonSaga({
    apiUrl: "bookmarks_save",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(bookmarkSaveSuccess(data))
      if (Object.keys(homeFeedData).length > 0 && homeFeedData.news)
      {
        const updatedNews = homeFeedData.news.map(item => {
          if (item.news_feed_id === action.payload.news_feed_id) {
            return {
              ...item,
              is_bookmarked: item.is_bookmarked === 0 ? 1 : 0,
            };
          }
          return item;
        });
        store.dispatch(homeFeedSuccess({ ...homeFeedData, news: updatedNews }));
      }
      if (Object.keys(bookmarksFeedData).length > 0)
      {
        const item = bookmarksFeedData.news.find(item => item.news_feed_id === action.payload.news_feed_id);
        if (item) {
          const updatedNews = bookmarksFeedData.news.filter(item => item.news_feed_id !== action.payload.news_feed_id);
          store.dispatch(bookmarksFeedSuccess({ ...bookmarksFeedData, news: updatedNews, total_news: bookmarksFeedData.total_news - 1 }));
        } 
      }

    },
    failure: bookmarkSaveFailure,
  });
}

function* singleNewsAPI(action) {
  yield getCommonSaga({
    apiUrl: "news_feeds_view",
    payload: action.payload,
    successNotify: false,
    success: singleNewsSuccess,
    failure: singleNewsFailure,
  });
}

function* fetchStaticPageAPI(action) {
  yield getCommonSaga({
    apiUrl: "static_pages",
    payload: action.payload,
    successNotify: false,
    success: fetchStaticPageSuccess,
    failure: fetchStaticPageFailure,
  });
}

function* newsLetterAPI(action) {
  yield getCommonSaga({
    apiUrl: "newsletter_save",
    payload: action.payload,
    successNotify: true,
    success: newsLetterSuccess,
    failure: newsLetterFailure,
  });
}

function* unSubscribeAPI(action) {
  yield getCommonSaga({
    apiUrl: "newsletter_unsubscribe",
    payload: action.payload,
    successNotify: false,
    errorNotify: false,
    success: unSubscribeSuccess,
    failure: unSubscribeFailure,
  });
}

export default function* HomeSaga() {
  yield all([
    yield takeLatest('home/settingsJsonStart', settingsJsonAPI),
    yield takeLatest('home/homeFeedStart', homeFeedAPI),
    yield takeLatest('home/moreHomeFeedStart', homeFeedAPI),
    yield takeLatest('home/bookmarksFeedStart', bookmarksFeedAPI),
    yield takeLatest('home/moreBookmarksFeedStart', bookmarksFeedAPI),
    yield takeLatest('home/bookmarkSaveStart', bookmarkSaveAPI),
    yield takeLatest('home/singleNewsStart', singleNewsAPI),
    yield takeLatest("home/fetchStaticPageStart", fetchStaticPageAPI),
    yield takeLatest("home/newsLetterStart", newsLetterAPI),
    yield takeLatest("home/unSubscribeStart", unSubscribeAPI),
  ]);
}

