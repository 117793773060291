import { initializeApp } from "firebase/app";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyDIN1gopOHlETBLSugLIezq5QhMwvODH5Y",
//   authDomain: "customer-support-2b7c5.firebaseapp.com",
//   projectId: "customer-support-2b7c5",
//   storageBucket: "customer-support-2b7c5.appspot.com",
//   messagingSenderId: "804717822457",
//   appId: "1:804717822457:web:94a4890d9e661e523f7d28",
//   measurementId: "G-WZ41CHH1SE",
// };

// demo credentials 

const firebaseConfig = {
  apiKey: "AIzaSyDALNfRtHX9Tu82749r4D62QBfsZfU_ygI",
  authDomain: "sentient-matters-3fafe.firebaseapp.com",
  projectId: "sentient-matters-3fafe",
  storageBucket: "sentient-matters-3fafe.appspot.com",
  messagingSenderId: "668280430330",
  appId: "1:668280430330:web:34d17cd4f2199a46ddb343",
  measurementId: "G-23QWFCZHWR"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

const messaging = getMessaging(app);

export const getTokenFromFirebase = () => {
  return getToken(messaging, {
    vapidKey:
      "BPlT4cLXxl0AlkNIzoARYBxbvn5OQH1qbhWxOPWy0xkQ6hLaAL3JISK2e3VauG1FPZCTsPbVH9l9JxE509SGLBg",
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

//This function listens to push messages on the server

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(payload);
      resolve(payload);
    });
  });
