import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col, Container } from "react-bootstrap";

const FeedLoader = () => {
  return (
    <Container>
      <div className="news-list-header-sec">
        <div className="news-list-left-sec">
          <Skeleton height={40} width={120} />
        </div>
        <div className="news-list-right-sec">
          <Skeleton height={40} width={120} />
        </div>
      </div>
      {[...Array(3)].map((i, key) => (
        <div className="news-list-body-sec" key={key}>
          <div className="news-list-box">
            <div className="news-list-link">
              <div className="news-list-card">
                <div className="news-list-left-sec">
                  <div className="news-list-img-sec-1">
                    <Skeleton width="100%" height={320} />
                  </div>
                </div>
                <div className="news-list-right-sec">
                  <div className="news-list-skeleton-sec">
                    <Skeleton height={20}/>
                    <div className="news-info-body-sec">
                      <div className="news-info-publish-date-sec mb-3">
                        <Skeleton width={120} height={20} />
                      </div>
                      <div className="news-info-desc">
                        <Skeleton count={5} height={10} />
                      </div>
                      <Skeleton width={120} height={40} className="mt-3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Container>
  );
};

export default FeedLoader;
