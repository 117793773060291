import axios from "axios";

const useSummary = () => {
  const handleSummarize = async (data) => {
    try {
      const response = await axios.post(
        "http://13.59.2.142:5005/summarize_webpage",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        },        
      );
      return response.data;
    } catch (err) {
      return err;
    }
  };

  return {
    handleSummarize,
  };
};

export default useSummary;
