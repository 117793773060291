/* eslint-disable require-yield */
import { takeEvery } from 'redux-saga/effects';

function* setSelectedCategorySaga(action) {
    if (action.type === 'home/setSelectedCategory') {
        localStorage.setItem('selectedCategory', JSON.stringify(action.payload));
    }
}

export function* selectedCategoryMiddlewareSaga() {
    yield takeEvery('home/setSelectedCategory', setSelectedCategorySaga);
}
