import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  newsLetterStart,
  unSubscribeStart,
} from "../../store/slices/HomeSlice";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import Skeleton from "react-loading-skeleton";

const UnSubscribeIndex = (props) => {

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const unSubscribe = useSelector((state) => state.home.unSubscribe);
  const newsLetter = useSelector((state) => state.home.newsLetter);
  const navigate = useNavigate();

  useEffect(() => {
    let email = searchParams.get("email");
    email && dispatch(unSubscribeStart({ email: email }));
  }, []);

  return (
    <>
      <div className="unsubscribe-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={6}>
              {unSubscribe.buttonDisable ? (
                <div className="unsubscribe-card">
                  <Skeleton height={250} width={250} />
                  <Skeleton height={30} width={300} />
                  <Skeleton height={20} count={2} width={500} />
                  <Skeleton height={50} width={200} />
                </div>
              ) : Object.keys(unSubscribe.data).length > 0 ? (
                <div className="unsubscribe-card">
                  <Image
                    className="unsubscribe-img"
                    src={
                      window.location.origin + "/assets/img/unsubscibe-img.png"
                    }
                  />
                  <h4>
                    {Object.keys(newsLetter.data).length > 0 ? "Subscribed" : "Unsubscribed"} Successfully
                  </h4>
                  <p>
                    {!Object.keys(newsLetter.data).length > 0
                      ? "You have been successfully unsubscibe from email communications. If you did this in error, you may resubscribe by clicking the button below."
                      : "Thanks for subscribing with us. Will send the updates to your email"}
                  </p>
                  <div className="resubscribe-btn-sec">
                    {!Object.keys(newsLetter.data).length > 0 ? (
                      <Button
                        className="resubscribe-btn"
                        disabled={newsLetter.buttonDisable}
                        onClick={() => dispatch(newsLetterStart({ email_address: unSubscribe.data.news_letter.email_address }))}>
                        {newsLetter.buttonDisable ? "Loading" : "Re-subscribe"}
                      </Button>
                    ) : (
                      <Button className="resubscribe-btn" onClick={()=> navigate("/")}>
                        Home
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="unsubscribe-card">
                  <Image
                    className="unsubscribe-img"
                    src={
                      window.location.origin +
                      "/assets/img/unsubscribe-failed.png"
                    }
                  />
                  <h4>Unsubscribe Failed</h4>
                  <p>
                    Apologies, you haven't subscribed to news letter.
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UnSubscribeIndex;
