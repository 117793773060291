import React, { useState } from "react";
import HeaderIndex from "./Header/HeaderIndex";
import FooterIndex from "./Footer/FooterIndex";

const MainLayout = (props) => {

  const [theme, setTheme] = useState("light");

  return (
    <div className={theme == "dark" ? "dark-mode" : ""}>
      <HeaderIndex setTheme={setTheme} theme={theme} />
        <div className="main-wrapper">
          {props.children}
        </div>
      <FooterIndex/>
    </div>
  );
};

export default MainLayout;
