import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    categoriesList: getCommonInitialState(),
};

const SettingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {

        //categoriesList
        categoriesListStart: (state) => { state.categoriesList = getCommonStart() },
        categoriesListSuccess: (state, action) => { state.categoriesList = getCommonSuccess(action.payload) },
        categoriesListFailure: (state, action) => { state.categoriesList = getCommonFailure(action.payload) },

    }
});


export const {
    categoriesListStart,
    categoriesListSuccess,
    categoriesListFailure,
} = SettingsSlice.actions;

export default SettingsSlice.reducer;