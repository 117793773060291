import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";
const storedSelectedCategory = localStorage.getItem('selectedCategory');

const initialState = {
    theme: "",
    selectedCategory: storedSelectedCategory ? JSON.parse(storedSelectedCategory) : {
        label: "All",
        value: ""
    },
    settingsJson: getCommonInitialState(),
    homeFeed: getCommonInitialState(),
    bookmarkSave: getCommonInitialState(),
    bookmarksFeed: getCommonInitialState(),
    singleNews: getCommonInitialState(),
    pageData: getCommonInitialState(),
    newsLetter: getCommonInitialState(),
    unSubscribe: getCommonInitialState(),
};

const HomeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {

        //set theme
        setTheme: (state, action) => { state.theme = action.payload },

        // set selected category
        setSelectedCategory: (state, action) => { state.selectedCategory = action.payload },

        // Settings JSON
        settingsJsonStart: (state, action) => { state.settingsJson = getCommonStart() },
        settingsJsonSuccess: (state, action) => { state.settingsJson = getCommonSuccess(action.payload) },
        settingsJsonFailure: (state, action) => { state.settingsJson = getCommonFailure(action.payload) },

        //homeFeed
        homeFeedStart: (state) => { state.homeFeed = getCommonStart() },
        homeFeedSuccess: (state, action) => { state.homeFeed = getCommonSuccess(action.payload) },
        homeFeedFailure: (state, action) => { state.homeFeed = getCommonFailure(action.payload) },
        moreHomeFeedStart: (state) => { state.homeFeed = state.homeFeed },

        //homeFeed
        bookmarksFeedStart: (state) => { state.bookmarksFeed = getCommonStart() },
        bookmarksFeedSuccess: (state, action) => { state.bookmarksFeed = getCommonSuccess(action.payload) },
        bookmarksFeedFailure: (state, action) => { state.bookmarksFeed = getCommonFailure(action.payload) },
        moreBookmarksFeedStart: (state) => { state.bookmarksFeed = state.bookmarksFeed },

        //bookmarks
        bookmarkSaveStart: (state, action) => { state.bookmarkSave = getCommonStart(action.payload) },
        bookmarkSaveSuccess: (state, action) => { state.bookmarkSave = getCommonSuccess(action.payload) },
        bookmarkSaveFailure: (state, action) => { state.bookmarkSave = getCommonFailure(action.payload) },

        //bookmarks
        singleNewsStart: (state, action) => { state.singleNews = getCommonStart(action.payload) },
        singleNewsSuccess: (state, action) => { state.singleNews = getCommonSuccess(action.payload) },
        singleNewsFailure: (state, action) => { state.singleNews = getCommonFailure(action.payload) },

        // Static Pages
        fetchStaticPageStart: (state) => { state.pageData = getCommonStart() },
        fetchStaticPageSuccess: (state, action) => { state.pageData = getCommonSuccess(action.payload) },
        fetchStaticPageFailure: (state, action) => { state.pageData = getCommonFailure(action.payload) },

        // News Letter
        newsLetterStart: (state) => { state.newsLetter = getCommonStart() },
        newsLetterSuccess: (state, action) => { state.newsLetter = getCommonSuccess(action.payload) },
        newsLetterFailure: (state, action) => { state.newsLetter = getCommonFailure(action.payload) },

        // Unsubscribe
        unSubscribeStart: (state) => { state.unSubscribe = getCommonStart() },
        unSubscribeSuccess: (state, action) => { state.unSubscribe = getCommonSuccess(action.payload) },
        unSubscribeFailure: (state, action) => { state.unSubscribe = getCommonFailure(action.payload) },

    }
});


export const {
    setTheme,
    homeFeedStart,
    homeFeedSuccess,
    homeFeedFailure,
    moreHomeFeedStart,
    settingsJsonFailure,
    settingsJsonStart,
    settingsJsonSuccess,
    bookmarkSaveFailure,
    bookmarkSaveStart,
    bookmarkSaveSuccess,
    setSelectedCategory,
    bookmarksFeedFailure,
    bookmarksFeedStart,
    bookmarksFeedSuccess,
    moreBookmarksFeedStart,
    singleNewsFailure,
    singleNewsStart,
    singleNewsSuccess,
    fetchStaticPageFailure,
    fetchStaticPageStart,
    fetchStaticPageSuccess,
    newsLetterFailure,
    newsLetterStart,
    newsLetterSuccess,
    unSubscribeFailure,
    unSubscribeStart,
    unSubscribeSuccess,
} = HomeSlice.actions;

export default HomeSlice.reducer;