import React from "react";
import { Image } from "react-bootstrap";

const SomethingWentWrong = () => {
  return (
    <div className="something-went-wrong-sec">
      <div className="no-data-found-card">
        <Image
          className="no-data-found"
          src={
            window.location.origin +
            "/assets/img/wrong.png"
          }
        />
        <h4>
          Something Went Wrong
        </h4>
        <button
          className="default-btn btn btn-primary"
          onClick={() => window.location.reload()}
        >
          Reload
        </button>
      </div>
    </div>
  );
};

export default SomethingWentWrong;
