import './App.css';
import Base from "./components/Base";
import { Provider } from "react-redux";
import store from "./store";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'

function App() {

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        // Service Worker registration was successful
        console.log("Service Worker registered with scope:", registration.scope);
      })
      .catch((error) => {
        // Service Worker registration failed
        console.error("Service Worker registration failed:", error);
      });
  }
  
  return (
    <Provider store={store}>
      <Base />
    </Provider>
  );
}

export default App;
