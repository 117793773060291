import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NewsCategoriesListIndex from "../LandingPage/NewsCatgeoriesListIndex";
import {
  bookmarkSaveStart,
  singleNewsStart,
} from "../../store/slices/HomeSlice";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  RedditShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  RedditIcon,
  TelegramIcon,
} from "react-share";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const NewsIndex = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const singleNews = useSelector((state) => state.home.singleNews);
  const bookmarkSave = useSelector((state) => state.home.bookmarkSave);
  const [deviceToken, setDeviceToken] = useState("");
  const [filter, setFilter] = useState({
    unique_id: params.news_feed_unique_id,
  });

  useEffect(() => {
    deviceToken && dispatch(singleNewsStart(filter));
  }, [filter]);

  async function getDeviceUniqueID() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    setDeviceToken(result.visitorId);
  }

  useEffect(() => {
    getDeviceUniqueID();
  }, []);

  const handleBookmark = (news_feed_id) => {
    dispatch(
      bookmarkSaveStart({
        news_feed_id: news_feed_id,
      })
    );
  };

  useEffect(() => {
    setFilter({ ...filter, device_unique_id: deviceToken });
  }, [deviceToken]);

  const popoverBottom = (news) => {
    return (
      <Popover id="popover-positioned-bottom">
        <div className="social-share-sec m-3">
          <div className="text-center social-link">
            <div className="Demo__some-network">
              <EmailShareButton
                url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
                subject={news.title}
                body={news.title}
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div>
          <div className="text-center social-link">
            <WhatsappShareButton
              url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              title={news.title}
              separator=":: "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <div className="text-center social-link">
            <FacebookShareButton
              url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              quote={news.title}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div className="text-center social-link">
            <TwitterShareButton
              url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              title={news.title}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
          <div className="text-center social-link">
            <RedditShareButton
              url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              title={news.title}
              windowWidth={660}
              windowHeight={460}
              className="Demo__some-network__share-button"
            >
              <RedditIcon size={32} round />
            </RedditShareButton>
          </div>
          <div className="text-center social-link">
            <TelegramShareButton
              url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              title={news.title}
              windowWidth={660}
              windowHeight={460}
              className="Demo__some-network__share-button"
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>
          <div className="text-center social-link">
            <CopyToClipboard
              onCopy={() => getSuccessNotificationMessage("Link copied")}
              text={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              windowWidth={660}
              windowHeight={460}>
              <button className="primary-share-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="#fff">
                  <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                </svg>
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </Popover>
    );
  };

  return (
    <div className="news-landing-page-sec">
      <NewsCategoriesListIndex />
      <div className="news-list-sec">
        <Container>
          <div className="news-list-body-sec">
            <div className="news-list-box">
              {singleNews.loading ? (
                <div className="news-list-body-sec">
                <div className="news-list-box">
                  <div className="news-list-link">
                    <div className="news-list-card">
                      <div className="news-list-left-sec">
                        <div className="news-list-img-sec-1">
                          <Skeleton width="100%" height={320} />
                        </div>
                      </div>
                      <div className="news-list-right-sec">
                        <div className="news-list-skeleton-sec">
                          <Skeleton height={20}/>
                          <div className="news-info-body-sec">
                            <div className="news-info-publish-date-sec mb-3">
                              <Skeleton width={120} height={20} />
                            </div>
                            <div className="news-info-desc">
                              <Skeleton count={5} height={10} />
                            </div>
                            <Skeleton width={120} height={40} className="mt-3" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ) : (Object.keys(singleNews.data).length > 0 && Object.keys(singleNews.data.news_feed).length > 0) ? (
                <div className="news-list-link">
                  <div className="news-list-card">
                    <div className="news-list-left-sec">
                      <div className="news-list-img-sec">
                        <CustomLazyLoad
                          className="news-list-img"
                          src={singleNews.data.news_feed.picture}
                        />
                      </div>
                    </div>
                    <div className="news-list-right-sec">
                      <div className="news-list-content-sec">
                        <div className="news-info-header-sec">
                          {/* <div className="news-category-list-1">
                          {singleNews.data.news_feed.categories.length > 0 &&
                            singleNews.data.news_feed.categories.map((category, key) => (
                              <h4 className="category-title" key={key}>
                                {category.name}
                              </h4>
                            ))}
                          </div> */}
                          <div className="news-list-action-btn-sec">
                          <Button
                            variant="link"
                            className="news-bookmark-icon-sec"
                            onClick={() =>
                              handleBookmark(singleNews.data.news_feed.news_feed_id)
                            }
                            disabled={
                              bookmarkSave.buttonDisable &&
                              singleNews.data.news_feed.news_feed_id ==
                                bookmarkSave.data.news_feed_id
                            }
                          >
                            {singleNews.data.news_feed.is_bookmarked ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                              <path
                                      fill="url(#paint0_linear_1073_8)"
                                      stroke="#6661E7"
                                      strokeWidth="1.111"
                                      d="M6.663 1.111h6.667a4.444 4.444 0 014.444 4.447v11.338a2.001 2.001 0 01-2.833 1.81l-4.022-1.832a2.222 2.222 0 00-1.845 0l-4.022 1.833a1.999 1.999 0 01-2.833-1.811V5.558a4.447 4.447 0 014.444-4.447z"
                              ></path>
                              <defs>
                              <linearGradient
                                        id="paint0_linear_1073_8"
                                        x1="3.231"
                                        x2="18.135"
                                        y1="6.121"
                                        y2="6.82"
                                        gradientUnits="userSpaceOnUse"
                              >
                              <stop stopColor="#9747FF"></stop>
                              <stop offset="1" stopColor="#2F50FE"></stop>
                              </linearGradient>
                              </defs>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 17 18"
                              >
                                <path
                                  stroke="#000"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5.5 1h6a3.999 3.999 0 014 4.002v10.204a1.802 1.802 0 01-2.55 1.63l-3.62-1.65a2 2 0 00-1.66 0l-3.62 1.65a1.799 1.799 0 01-2.55-1.63V5.002A4.002 4.002 0 015.5 1z"
                                ></path>
                              </svg>
                            )}
                          </Button>
                          {singleNews.data.news_feed.external_link && (
                            <OverlayTrigger
                              trigger="click"
                              placement="bottom"
                              overlay={popoverBottom(singleNews.data.news_feed)}
                            >
                              <Button className="news-share-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 17 15"
                                >
                                  <path
                                    fill="#000"
                                    d="M7.051.984c.062-.69.732-1.258 1.407-.843 1.405.884 2.731 1.891 4.013 2.968 1.583 1.343 2.853 2.609 3.752 3.62.39.44.354 1.134-.02 1.554-.89.99-1.832 1.925-2.821 2.801-1.565 1.377-3.185 2.711-4.95 3.781-.598.363-1.246-.052-1.361-.695l-.014-.098-.183-3.1a6.247 6.247 0 00-4.021 1.58l-.214.194-.104.09-.199.166-.097.077-.186.138a3.8 3.8 0 01-.176.118l-.166.096C.931 13.845.5 13.479.5 11.837c0-3.82 2.62-7.221 6.162-7.736l.21-.027.18-3.09zm1.542 1.279l-.156 3.012a.45.45 0 01-.11.276.394.394 0 01-.25.132l-1.1.123c-2.474.318-4.438 2.397-4.802 5.114a7.706 7.706 0 014.146-1.659l.32-.02 1.377-.031a.388.388 0 01.283.115.448.448 0 01.128.294l.16 3.12c1.294-.885 2.527-1.905 3.765-2.993.8-.713 1.57-1.465 2.307-2.251l-.208-.222-.45-.463a39.813 39.813 0 00-2.53-2.336 37.46 37.46 0 00-2.88-2.211z"
                                  ></path>
                                </svg>
                              </Button>
                            </OverlayTrigger>
                          )}
                          </div>
                        </div>
                        <div className="news-info-body-sec">
                          <h4>{singleNews.data.news_feed.title}</h4>
                          <div className="news-category-list-item">
                            <div className="news-info-publish-date-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 21 21"
                              >
                                <path
                                  fill="#676767"
                                  stroke="#676767"
                                  strokeLinecap="round"
                                  strokeMiterlimit="10"
                                  strokeWidth="0.01"
                                  d="M6.5 11.5a1 1 0 100-2 1 1 0 000 2zM14.5 11.5a1 1 0 100-2 1 1 0 000 2zM10.5 11.5a1 1 0 100-2 1 1 0 000 2zM6.5 15.5a1 1 0 100-2 1 1 0 000 2zM14.5 15.5a1 1 0 100-2 1 1 0 000 2zM10.5 15.5a1 1 0 100-2 1 1 0 000 2z"
                                ></path>
                                <path
                                  stroke="#676767"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6.5 1.5v4M14.5 1.5v4M14.5 3.5h-8a5 5 0 00-5 5v6a5 5 0 005 5h8a5 5 0 005-5v-6a5 5 0 00-5-5z"
                                ></path>
                              </svg>
                              <span>{singleNews.data.news_feed.publish_time_formatted}</span>
                            </div>
                              {/* <div className="news-category-list-1">
                                  {news.categories.length > 0 && news.categories.map((category, key) => (
                                    <h4 className="category-title" key={key}>{category.name}</h4>
                                  ))}
                                </div> */}
                          </div>
                          <div className="news-info-desc">
                            <p>{singleNews.data.news_feed.description}</p>
                          </div>
                          {singleNews.data.news_feed.external_link && (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={singleNews.data.news_feed.external_link}
                            >
                              Continue reading...
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default NewsIndex;
