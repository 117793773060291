import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchStaticPageStart } from "../../store/slices/HomeSlice";
import Skeleton from "react-loading-skeleton";

const StaticIndex = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageData = useSelector((state) => state.home.pageData);

  useEffect(() => {
    dispatch(fetchStaticPageStart({ type: params.unique_id }));
  }, [params.unique_id]);

  return (
    <>
      <div className="news-static-page-sec">
        <Container>
          {pageData.loading ? (
            <div>
              <Skeleton count={1} height={40} className="mb-3" />
              {[...Array(2)].map((i, key) => (
                <div className="my-3">
                  <Skeleton count={1} height={30} className="mb-3" />
                  <Skeleton count={5} height={15} />
                </div>
              ))}
            </div>
          ) : Object.keys(pageData.data).length > 0 &&
            Object.keys(pageData.data.static_pages).length > 0 ? (
            <>
              <div className="news-static-header-sec">
                <h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                    fill="none"
                    viewBox="0 0 43 44"
                    style={{ cursor: "pointer" }}
                    onClick={()=> navigate("/")}
                  >
                    <path
                      fill="#000"
                      d="M24.293 16.71a.994.994 0 010 1.41L20.413 22l3.88 3.88a.997.997 0 11-1.41 1.41l-4.59-4.59a.996.996 0 010-1.41l4.59-4.59c.38-.38 1.02-.38 1.41.01z"
                    ></path>
                    <rect
                      width="41.585"
                      height="42.166"
                      x="-0.5"
                      y="0.5"
                      stroke="#DDE1E6"
                      rx="20.792"
                      transform="matrix(-1 0 0 1 41.585 .417)"
                    ></rect>
                  </svg>
                  <span>{pageData.data.static_pages.title.slice(0,1).toUpperCase()+pageData.data.static_pages.title.slice(1)}</span>
                </h2>
              </div>
              <div className="news-static-body-sec">
                <div className="news-static-card">
                  <p
                    className="text-capitalize"
                    dangerouslySetInnerHTML={{
                      __html: pageData.data.static_pages.description,
                    }}
                  ></p>
                </div>
              </div>
            </>
          ) : (
            "No Data Found"
          )}
        </Container>
      </div>
    </>
  );
};

export default StaticIndex;
