import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import { categoriesListFailure, categoriesListSuccess } from "../slices/SettingsSlice";


function* categoriesListAPI(action) {
  yield getCommonSaga({
    apiUrl: "categories_list",
    payload: action.payload,
    success: categoriesListSuccess,
    failure: categoriesListFailure,
  });
}

export default function* SettingsSaga() {
  yield all([
    yield takeLatest('settings/categoriesListStart', categoriesListAPI),
  ]);
}

