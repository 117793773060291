import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import EmptyLayout from "../layouts/EmptyLayout";
import MainLayout from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import StaticIndex from "../Static/StaticIndex";
import { useDispatch, useSelector } from "react-redux";
import { settingsJsonStart } from "../../store/slices/HomeSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import CustomizeIndex from "../Customize/CustomizeIndex";
import NewsIndex from "../News/NewsIndex";
import ScrollToTop from "../Helper/ScrollToTop";
import UnSubscribeIndex from "../UnSubscribe/UnSubscribeIndex";

const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");
  let authentication =
    userId && accessToken && userLoginStatus == "true" ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(true);
  const settingsJson = useSelector((state) => state.home.settingsJson);

  useEffect(() => {
    dispatch(settingsJsonStart());
    return () => {
      localStorage.removeItem("userId");
      localStorage.removeItem("userToken");
    };
  }, []);

  return settingsJson.loading ? (
    <PageLoader />
  ) : Object.keys(settingsJson.data).length > 0 ? (
    <>
      <>
        <ToastContainer />
        {/* <Helmet>
				<title>{configuration.get("configData.site_name")}</title>
				<link
					rel="icon"
					type="image/png"
					href={configuration.get("configData.site_icon")}
				/>
				<script src={configuration.get("configData.head_scripts")}></script>
			</Helmet> */}
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route
              path={"/"}
              element={
                <AppRoute component={LandingPageIndex} layout={MainLayout} />
              }
            />
            <Route
              path={"/customize"}
              element={
                <AppRoute component={CustomizeIndex} layout={MainLayout} />
              }
            />
            <Route
              path={"/feed/:category_name"}
              element={
                <AppRoute component={LandingPageIndex} layout={MainLayout} />
              }
            />
            <Route
              path={"/page/:unique_id"}
              element={<AppRoute component={StaticIndex} layout={MainLayout} />}
            />
            <Route
              path={"/news/:news_feed_unique_id"}
              element={<AppRoute component={NewsIndex} layout={MainLayout} />}
            />
            <Route
              path={"/unsubscribe"}
              element={
                <AppRoute component={UnSubscribeIndex} layout={MainLayout} />
              }
            />
          </Routes>
        </BrowserRouter>
      </>
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(settingsJsonStart())}
    />
  );
};

export default App;
