import React from "react";
import { TailSpin } from "react-loader-spinner";

const PageLoader = () => {
  return (
    <div className="something-went-wrong-sec">
      <TailSpin
        visible={true}
        width="150"
        wrapperStyle={{}}
        color="#9747ff"
      />
    </div>
  );
};

export default PageLoader;
