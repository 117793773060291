import React from "react";
import { Image } from "react-bootstrap";

const NoDataFound = () => {
  return (
    <>
      <div className="no-data-found-card">
        <Image
          className="no-data-found"
          src={
            window.location.origin +
            "/assets/img/no-data-found.png"
          }
        />
      </div>
    </>
  );
};

export default NoDataFound;
