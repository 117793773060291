import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Button,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  bookmarkSaveStart,
  bookmarksFeedStart,
  homeFeedStart,
  moreBookmarksFeedStart,
  moreHomeFeedStart,
} from "../../store/slices/HomeSlice";
import FeedLoader from "../Helper/FeedLoader";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  RedditShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  RedditIcon,
  TelegramIcon,
} from "react-share";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { getTokenFromFirebase, onMessageListener } from "../../firebase/firebase-config";
import NoDataFound from "../Helper/NoDataFound";
import { set } from "react-global-configuration";

const NewsListIndex = (props) => {
  const options = [
    { value: "today", label: "Today" },
    { value: "last week", label: "Last Week" },
    { value: "last month", label: "Last Month" },
  ];

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "max-content",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      // minWidth: "100%",
      fontSize: "0.9em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transaprent!important",
      border: "1px solid #ACACAC4D!important",
      borderRadius: "10px",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1em",
      fontWeight: "500",
      color: "#212325",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#212325",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "1em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#212325!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#212325",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const homeFeed = useSelector((state) => state.home.homeFeed);
  const bookmarksFeed = useSelector((state) => state.home.bookmarksFeed);
  const bookmarkSave = useSelector((state) => state.home.bookmarkSave);
  const selectedCategory = useSelector((state) => state.home.selectedCategory);
  const [deviceToken, setDeviceToken] = useState("");
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState({
    category_ids: selectedCategory.value,
  });
  const [skipRender, setSkipRender] = useState(true);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [loading, setLoading] = useState(false);

  async function getDeviceUniqueID() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    setDeviceToken(result.visitorId);
  }

  const getFCM = async () => {
    let fcm_token = await getTokenFromFirebase();
    if (fcm_token) {
      setFilter({
        ...filter,
        device_token: fcm_token,
      });
    }
  };

  useEffect(() => {
    getDeviceUniqueID();
    getFCM();
  }, []);

  useEffect(() => {
    let newValues = {
      ...filter,
      search_key: searchParams.get("search_key") || "",
    };
    if (filter.device_unique_id) {
      filter.category_ids === 0
        ? dispatch(
            bookmarksFeedStart({
              ...newValues,
              skip: 0,
              take: 12,
            })
          )
        : dispatch(
            homeFeedStart({
              ...newValues,
              skip: 0,
              take: 12,
            })
          );
    }
  }, [searchParams]);

  useEffect(() => {
    setFilter({ ...filter, device_unique_id: deviceToken });
  }, [deviceToken]);

  useEffect(() => {
    setFilter({
      ...filter,
      category_ids: selectedCategory.value,
    });
  }, [selectedCategory]);

  useEffect(() => {
    if (filter.device_unique_id /*&& filter.device_token*/) {
      filter.category_ids === 0
        ? dispatch(
            bookmarksFeedStart({
              ...filter,
              skip: 0,
              take: 12,
              id: localStorage.getItem("userId"),
              token: localStorage.getItem("userToken"),
            })
          )
        : dispatch(
            homeFeedStart({
              ...filter,
              skip: 0,
              take: 12,
            })
          );
    }
  }, [filter]);

  const calculateDays = (time) => {
    return Math.floor(
      Math.abs(new Date() - new Date(time)) / (1000 * 60 * 60 * 24)
    );
  };

  const fetchMoreData = () => {
    setLoading(true);
    dispatch(
      moreHomeFeedStart({
        ...filter,
        device_token: deviceToken,
        skip: homeFeed.data.news.length,
        take: 12,
      })
    );
  };

  useEffect(() => {
    if(!homeFeed.loading && homeFeed.data.news.length > 0){
      setLoading(false);
    }
    if(!bookmarksFeed.loading && bookmarksFeed.data.news.length > 0){
      setLoading(false);
    }
  }, [homeFeed, bookmarksFeed]);

  const fetchMoreBookmarksData = () => {
    dispatch(
      moreBookmarksFeedStart({
        search_key: filter.search_key ? filter.search_key : "",
        device_token: deviceToken,
        id: localStorage.getItem("userId"),
        token: localStorage.getItem("userToken"),
        skip: bookmarksFeed.data.news.length,
        take: 12,
      })
    );
  };

  const popoverBottom = (news) => {
    return (
      <Popover id="popover-positioned-bottom">
        <div className="social-share-sec m-3">
          <div className="text-center social-link">
            <div className="Demo__some-network">
              <EmailShareButton
                url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
                subject={news.title}
                body={news.title}
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div>
          <div className="text-center social-link">
            <WhatsappShareButton
              url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              title={news.title}
              separator=":: "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <div className="text-center social-link">
            <FacebookShareButton
              url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              quote={news.title}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div className="text-center social-link">
            <TwitterShareButton
              url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              title={news.title}
              className="Demo__some-network__share-button"
            >
              {/* <TwitterIcon size={32} round /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 512 512"
              >
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
            </TwitterShareButton>
          </div>
          <div className="text-center social-link">
            <RedditShareButton
              url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              title={news.title}
              windowWidth={660}
              windowHeight={460}
              className="Demo__some-network__share-button"
            >
              <RedditIcon size={32} round />
            </RedditShareButton>
          </div>
          <div className="text-center social-link">
            <TelegramShareButton
              url={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              title={news.title}
              windowWidth={660}
              windowHeight={460}
              className="Demo__some-network__share-button"
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>
          <div className="text-center social-link">
            <CopyToClipboard
              onCopy={() => getSuccessNotificationMessage("Link copied")}
              text={`${window.location.origin}/news/${news.news_feed_unique_id}`}
              windowWidth={660}
              windowHeight={460}
            >
              <button className="primary-share-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="#fff"
                >
                  <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                </svg>
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </Popover>
    );
  };

  const handleBookmark = (news_feed_id) => {
    dispatch(
      bookmarkSaveStart({
        id: localStorage.getItem("userId"),
        token: localStorage.getItem("userToken"),
        news_feed_id: news_feed_id,
      })
    );
  };

  useEffect(() => {
    if (
      !homeFeed.loading &&
      Object.keys(homeFeed.data).length > 0 &&
      Object.keys(homeFeed.data.user_device).length > 0
    ) {
      localStorage.setItem("userToken", homeFeed.data.user_device.token);
      localStorage.setItem("userId", homeFeed.data.user_device.user_device_id);
      if (skipRender) {
        setFilter({
          ...filter,
          device_token: homeFeed.data.user_device.token,
        });
      }
    }
    setSkipRender(false);
  }, [homeFeed]);

  onMessageListener()
    .then((payload) => {
      setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
    })
  .catch((err) => console.log('failed: ', err));

  useEffect(() => {
    if (notification?.title ) {
     getSuccessNotificationMessage(notification.title+" "+notification.body)
    }
  }, [notification])

  return (
    <>
      <div className="news-list-sec">
        {(selectedCategory.value !== 0 && homeFeed.loading) ||
        (selectedCategory.value === 0 && bookmarksFeed.loading) ? (
          <FeedLoader />
        ) : (selectedCategory.value !== 0 &&
            Object.keys(homeFeed.data).length > 0) ||
          (selectedCategory.value === 0 &&
            Object.keys(bookmarksFeed.data).length > 0) ? (
          <Container>
            <div className="news-list-header-sec">
              <div className="news-list-left-sec">
                <h2>
                  {selectedCategory.label.charAt(0).toUpperCase() +
                    selectedCategory.label.slice(1)}{" "}
                  <span>
                    (
                    {selectedCategory.value !== 0
                      ? homeFeed.data.total_news
                      : bookmarksFeed.data.total_news}
                    )
                  </span>
                </h2>
              </div>
              {/* <div className="news-list-right-sec">
                <Select
                  // defaultValue={selectedOption}
                  options={options}
                  styles={customStyles}
                  isSearchable={false}
                  placeholder={
                    <div className="filter-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 22 18"
                      >
                        <path
                          fill="#000"
                          stroke="#000"
                          strokeWidth="0.7"
                          d="M10.214 8.18c0 .163-.067.32-.187.435a.65.65 0 01-.45.18h-7.66a.65.65 0 01-.452-.18.604.604 0 01-.187-.435c0-.163.067-.32.187-.435a.65.65 0 01.451-.18h7.66a.65.65 0 01.451.18c.12.115.187.272.187.435zM1.916 2.23h14.469a.65.65 0 00.451-.18.604.604 0 00.187-.435.604.604 0 00-.187-.435.65.65 0 00-.451-.18H1.916a.65.65 0 00-.451.18.604.604 0 00-.187.435c0 .164.067.32.187.436a.65.65 0 00.451.18zM7.874 14.13H1.916a.65.65 0 00-.451.18.604.604 0 00-.187.435c0 .163.067.32.187.435a.65.65 0 00.451.18h5.958a.65.65 0 00.451-.18.604.604 0 00.187-.435.604.604 0 00-.187-.435.65.65 0 00-.451-.18zm13.217-2.281a.65.65 0 00-.45-.18.65.65 0 00-.452.18l-3.166 3.05v-8.36a.604.604 0 00-.187-.435.65.65 0 00-.451-.18.65.65 0 00-.452.18.604.604 0 00-.187.436v8.36l-3.166-3.051a.652.652 0 00-.886.015.603.603 0 00-.016.855l4.256 4.102a.65.65 0 00.45.18.65.65 0 00.452-.18l4.255-4.103a.605.605 0 00.187-.435.605.605 0 00-.187-.434z"
                        ></path>
                      </svg>
                      Filter
                    </div>
                  }
                />
              </div> */}
            </div>
            {selectedCategory.value !== 0 ? (
              <InfiniteScroll
                dataLength={homeFeed.data.news.length}
                // next={fetchMoreData}
                hasMore={homeFeed.data.news.length < homeFeed.data.total_news}
                // loader={[...Array(1)].map((i, key) => (
                //   <div className="news-list-body-sec" key={key}>
                //     <div className="news-list-box">
                //       <div className="news-list-link">
                //         <div className="news-list-card">
                //           <div className="news-list-left-sec">
                //             <div className="news-list-img-sec-1">
                //               <Skeleton width="100%" height={320} />
                //             </div>
                //           </div>
                //           <div className="news-list-right-sec">
                //             <div className="news-list-skeleton-sec">
                //               <Skeleton height={20}/>
                //               <div className="news-info-body-sec">
                //                 <div className="news-info-publish-date-sec mb-3">
                //                   <Skeleton width={120} height={20} />
                //                 </div>
                //                 <div className="news-info-desc">
                //                   <Skeleton count={5} height={10} />
                //                 </div>
                //                 <Skeleton width={120} height={40} className="mt-3" />
                //               </div>
                //             </div>
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //   </div>))}
                >
                <div className="news-list-body-sec">
                  <div className="news-list-box">
                    {homeFeed.data.news.length > 0 ? (
                      homeFeed.data.news.map((news, key) => (
                        <div className="news-list-link" key={key}>
                          <div className="news-list-card">
                            <div className="news-list-left-sec">
                              <div className="news-list-img-sec" onClick={()=> navigate(`/news/${news.news_feed_unique_id}`)}>
                                <CustomLazyLoad
                                  className="news-list-img"
                                  src={news.picture}
                                />
                              </div>
                            </div>
                            <div className="news-list-right-sec">
                              <div className="news-list-content-sec">
                                <div className="news-info-header-sec">
                                  <div className="news-list-action-btn-sec">
                                    <OverlayTrigger
                                      placement="top"
                                      rootClose
                                      overlay={
                                        <Tooltip id="tooltip">
                                          <strong>
                                            {news.is_bookmarked
                                              ? "Remove from Bookmarks!"
                                              : "Add to bookmarks"}
                                          </strong>
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className="news-bookmark-icon-sec"
                                        onClick={() =>
                                          handleBookmark(news.news_feed_id)
                                        }
                                        disabled={
                                          bookmarkSave.buttonDisable &&
                                          news.news_feed_id ==
                                            bookmarkSave.data.news_feed_id
                                        }
                                      >
                                        {news.is_bookmarked ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="none"
                                            viewBox="0 0 15 18"
                                          >
                                            <path
                                              fill="url(#paint0_linear_222_373)"
                                              stroke="#fff"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M4.5 1h6a3.999 3.999 0 014 4.002v10.204a1.802 1.802 0 01-2.55 1.63l-3.62-1.65a2 2 0 00-1.66 0l-3.62 1.65a1.799 1.799 0 01-2.55-1.63V5.002A4.002 4.002 0 014.5 1z"
                                            ></path>
                                            <defs>
                                              <linearGradient
                                                id="paint0_linear_222_373"
                                                x1="1.411"
                                                x2="14.825"
                                                y1="5.509"
                                                y2="6.138"
                                                gradientUnits="userSpaceOnUse"
                                              >
                                                <stop stopColor="#9747FF"></stop>
                                                <stop
                                                  offset="1"
                                                  stopColor="#2F50FE"
                                                ></stop>
                                              </linearGradient>
                                            </defs>
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="none"
                                            viewBox="0 0 17 18"
                                          >
                                            <path
                                              stroke="#000"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M5.5 1h6a3.999 3.999 0 014 4.002v10.204a1.802 1.802 0 01-2.55 1.63l-3.62-1.65a2 2 0 00-1.66 0l-3.62 1.65a1.799 1.799 0 01-2.55-1.63V5.002A4.002 4.002 0 015.5 1z"
                                            ></path>
                                          </svg>
                                        )}
                                      </Button>
                                    </OverlayTrigger>
                                    {news.external_link && (
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        rootClose
                                        overlay={popoverBottom(news)}
                                      >
                                        <Button className="news-share-btn">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="none"
                                            viewBox="0 0 17 15"
                                          >
                                            <path
                                              fill="#000"
                                              d="M7.051.984c.062-.69.732-1.258 1.407-.843 1.405.884 2.731 1.891 4.013 2.968 1.583 1.343 2.853 2.609 3.752 3.62.39.44.354 1.134-.02 1.554-.89.99-1.832 1.925-2.821 2.801-1.565 1.377-3.185 2.711-4.95 3.781-.598.363-1.246-.052-1.361-.695l-.014-.098-.183-3.1a6.247 6.247 0 00-4.021 1.58l-.214.194-.104.09-.199.166-.097.077-.186.138a3.8 3.8 0 01-.176.118l-.166.096C.931 13.845.5 13.479.5 11.837c0-3.82 2.62-7.221 6.162-7.736l.21-.027.18-3.09zm1.542 1.279l-.156 3.012a.45.45 0 01-.11.276.394.394 0 01-.25.132l-1.1.123c-2.474.318-4.438 2.397-4.802 5.114a7.706 7.706 0 014.146-1.659l.32-.02 1.377-.031a.388.388 0 01.283.115.448.448 0 01.128.294l.16 3.12c1.294-.885 2.527-1.905 3.765-2.993.8-.713 1.57-1.465 2.307-2.251l-.208-.222-.45-.463a39.813 39.813 0 00-2.53-2.336 37.46 37.46 0 00-2.88-2.211z"
                                            ></path>
                                          </svg>
                                        </Button>
                                      </OverlayTrigger>
                                    )}
                                  </div>
                                </div>
                                <div className="news-info-body-sec">
                                  <h4 onClick={()=> navigate(`/news/${news.news_feed_unique_id}`)}>{news.title}</h4>
                                  <div className="news-category-list-item">
                                    <div className="news-info-publish-date-sec">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        fill="none"
                                        viewBox="0 0 21 21"
                                      >
                                        <path
                                          fill="#676767"
                                          stroke="#676767"
                                          strokeLinecap="round"
                                          strokeMiterlimit="10"
                                          strokeWidth="0.01"
                                          d="M6.5 11.5a1 1 0 100-2 1 1 0 000 2zM14.5 11.5a1 1 0 100-2 1 1 0 000 2zM10.5 11.5a1 1 0 100-2 1 1 0 000 2zM6.5 15.5a1 1 0 100-2 1 1 0 000 2zM14.5 15.5a1 1 0 100-2 1 1 0 000 2zM10.5 15.5a1 1 0 100-2 1 1 0 000 2z"
                                        ></path>
                                        <path
                                          stroke="#676767"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M6.5 1.5v4M14.5 1.5v4M14.5 3.5h-8a5 5 0 00-5 5v6a5 5 0 005 5h8a5 5 0 005-5v-6a5 5 0 00-5-5z"
                                        ></path>
                                      </svg>
                                      <span>{news.publish_time_formatted}</span>
                                    </div>
                                    {/* <div className="news-category-list-1">
                                        {news.categories.length > 0 && news.categories.map((category, key) => (
                                          <h4 className="category-title" key={key}>{category.name}</h4>
                                        ))}
                                      </div> */}
                                    <div className="news-list-action-btn-sec news-list-action-btn-sec-website-hide">
                                      <OverlayTrigger
                                        placement="top"
                                        rootClose
                                        overlay={
                                          <Tooltip id="tooltip">
                                            <strong>
                                              {news.is_bookmarked
                                                ? "Remove from Bookmarks!"
                                                : "Add to bookmarks"}
                                            </strong>
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          className="news-bookmark-icon-sec"
                                          onClick={() =>
                                            handleBookmark(news.news_feed_id)
                                          }
                                          disabled={
                                            bookmarkSave.buttonDisable &&
                                            news.news_feed_id ==
                                              bookmarkSave.data.news_feed_id
                                          }
                                        >
                                          {news.is_bookmarked ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              fill="none"
                                              viewBox="0 0 20 20"
                                            >
                                            <path
                                                    fill="url(#paint0_linear_1073_8)"
                                                    stroke="#6661E7"
                                                    strokeWidth="1.111"
                                                    d="M6.663 1.111h6.667a4.444 4.444 0 014.444 4.447v11.338a2.001 2.001 0 01-2.833 1.81l-4.022-1.832a2.222 2.222 0 00-1.845 0l-4.022 1.833a1.999 1.999 0 01-2.833-1.811V5.558a4.447 4.447 0 014.444-4.447z"
                                            ></path>
                                            <defs>
                                            <linearGradient
                                                      id="paint0_linear_1073_8"
                                                      x1="3.231"
                                                      x2="18.135"
                                                      y1="6.121"
                                                      y2="6.82"
                                                      gradientUnits="userSpaceOnUse"
                                            >
                                            <stop stopColor="#9747FF"></stop>
                                            <stop offset="1" stopColor="#2F50FE"></stop>
                                            </linearGradient>
                                            </defs>
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              fill="none"
                                              viewBox="0 0 17 18"
                                            >
                                              <path
                                                stroke="#000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.5 1h6a3.999 3.999 0 014 4.002v10.204a1.802 1.802 0 01-2.55 1.63l-3.62-1.65a2 2 0 00-1.66 0l-3.62 1.65a1.799 1.799 0 01-2.55-1.63V5.002A4.002 4.002 0 015.5 1z"
                                              ></path>
                                            </svg>
                                          )}
                                        </Button>
                                      </OverlayTrigger>
                                      {news.external_link && (
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="bottom"
                                          rootClose
                                          overlay={popoverBottom(news)}
                                        >
                                          <Button className="news-share-btn">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              fill="none"
                                              viewBox="0 0 17 15"
                                            >
                                              <path
                                                fill="#000"
                                                d="M7.051.984c.062-.69.732-1.258 1.407-.843 1.405.884 2.731 1.891 4.013 2.968 1.583 1.343 2.853 2.609 3.752 3.62.39.44.354 1.134-.02 1.554-.89.99-1.832 1.925-2.821 2.801-1.565 1.377-3.185 2.711-4.95 3.781-.598.363-1.246-.052-1.361-.695l-.014-.098-.183-3.1a6.247 6.247 0 00-4.021 1.58l-.214.194-.104.09-.199.166-.097.077-.186.138a3.8 3.8 0 01-.176.118l-.166.096C.931 13.845.5 13.479.5 11.837c0-3.82 2.62-7.221 6.162-7.736l.21-.027.18-3.09zm1.542 1.279l-.156 3.012a.45.45 0 01-.11.276.394.394 0 01-.25.132l-1.1.123c-2.474.318-4.438 2.397-4.802 5.114a7.706 7.706 0 014.146-1.659l.32-.02 1.377-.031a.388.388 0 01.283.115.448.448 0 01.128.294l.16 3.12c1.294-.885 2.527-1.905 3.765-2.993.8-.713 1.57-1.465 2.307-2.251l-.208-.222-.45-.463a39.813 39.813 0 00-2.53-2.336 37.46 37.46 0 00-2.88-2.211z"
                                              ></path>
                                            </svg>
                                          </Button>
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  </div>
                                  <div className="news-info-desc">
                                    <p>{news.description}</p>
                                  </div>
                                  {news.external_link && (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={news.external_link}
                                    >
                                      Read More...
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                  {(homeFeed.data.news.length < homeFeed.data.total_news) ? loading ? [...Array(3)].map((i, key) => ( <div className="news-list-body-sec" key={key}>
                    <div className="news-list-box">
                      <div className="news-list-link">
                        <div className="news-list-card">
                          <div className="news-list-left-sec">
                            <div className="news-list-img-sec-1">
                              <Skeleton width="100%" height={320} />
                            </div>
                          </div>
                          <div className="news-list-right-sec">
                            <div className="news-list-skeleton-sec">
                              <Skeleton height={20}/>
                              <div className="news-info-body-sec">
                                <div className="news-info-publish-date-sec mb-3">
                                  <Skeleton width={120} height={20} />
                                </div>
                                <div className="news-info-desc">
                                  <Skeleton count={5} height={10} />
                                </div>
                                <Skeleton width={120} height={40} className="mt-3" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)) : <div className="load-more-sec">
                    <button className="default-btn btn btn-primary" onClick={fetchMoreData}>
                      Load More
                    </button>
                    </div> : null}
                </div>
              </InfiniteScroll>
            ) : (
              <InfiniteScroll
                dataLength={bookmarksFeed.data.news.length}
                // next={fetchMoreBookmarksData}
                hasMore={
                  bookmarksFeed.data.news.length < bookmarksFeed.data.total_news
                }
                // loader={[...Array(1)].map((i, key) => (
                //   <div className="news-list-body-sec" key={key}>
                //     <div className="news-list-box">
                //       <div className="news-list-link">
                //         <div className="news-list-card">
                //           <div className="news-list-left-sec">
                //             <div className="news-list-img-sec-1">
                //               <Skeleton width="100%" height={320} />
                //             </div>
                //           </div>
                //           <div className="news-list-right-sec">
                //             <div className="news-list-skeleton-sec">
                //               <Skeleton height={20}/>
                //               <div className="news-info-body-sec">
                //                 <div className="news-info-publish-date-sec mb-3">
                //                   <Skeleton width={120} height={20} />
                //                 </div>
                //                 <div className="news-info-desc">
                //                   <Skeleton count={5} height={10} />
                //                 </div>
                //                 <Skeleton width={120} height={40} className="mt-3" />
                //               </div>
                //             </div>
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // ))}
              >
                <div className="news-list-body-sec">
                  <div className="news-list-box">
                    {bookmarksFeed.data.news.length > 0 ? (
                      bookmarksFeed.data.news.map((news, key) => (
                        <div className="news-list-link" key={key}>
                          <div className="news-list-card">
                            <div className="news-list-left-sec">
                              <div className="news-list-img-sec" onClick={()=> navigate(`/news/${news.news_feed_unique_id}`)}>
                                <CustomLazyLoad
                                  className="news-list-img"
                                  src={news.picture}
                                />
                              </div>
                            </div>
                            <div className="news-list-right-sec">
                              <div className="news-list-content-sec">
                                <div className="news-info-header-sec">
                                  <div className="news-list-action-btn-sec">
                                    <OverlayTrigger
                                      placement="top"
                                      rootClose
                                      overlay={
                                        <Tooltip id="tooltip">
                                          <strong>
                                            Remove from Bookmarks!
                                          </strong>
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="link"
                                        className="news-bookmark-icon-sec"
                                        onClick={() =>
                                          handleBookmark(news.news_feed_id)
                                        }
                                        disabled={
                                          bookmarkSave.buttonDisable &&
                                          news.news_feed_id ==
                                            bookmarkSave.data.news_feed_id
                                        }
                                      >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="none"
                                            viewBox="0 0 20 20"
                                          >
                                          <path
                                                  fill="url(#paint0_linear_1073_8)"
                                                  stroke="#6661E7"
                                                  strokeWidth="1.111"
                                                  d="M6.663 1.111h6.667a4.444 4.444 0 014.444 4.447v11.338a2.001 2.001 0 01-2.833 1.81l-4.022-1.832a2.222 2.222 0 00-1.845 0l-4.022 1.833a1.999 1.999 0 01-2.833-1.811V5.558a4.447 4.447 0 014.444-4.447z"
                                          ></path>
                                          <defs>
                                          <linearGradient
                                                    id="paint0_linear_1073_8"
                                                    x1="3.231"
                                                    x2="18.135"
                                                    y1="6.121"
                                                    y2="6.82"
                                                    gradientUnits="userSpaceOnUse"
                                          >
                                          <stop stopColor="#9747FF"></stop>
                                          <stop offset="1" stopColor="#2F50FE"></stop>
                                          </linearGradient>
                                          </defs>
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                    {news.external_link && (
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        rootClose
                                        overlay={popoverBottom(news)}
                                      >
                                        <Button className="news-share-btn">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="none"
                                            viewBox="0 0 17 15"
                                          >
                                            <path
                                              fill="#000"
                                              d="M7.051.984c.062-.69.732-1.258 1.407-.843 1.405.884 2.731 1.891 4.013 2.968 1.583 1.343 2.853 2.609 3.752 3.62.39.44.354 1.134-.02 1.554-.89.99-1.832 1.925-2.821 2.801-1.565 1.377-3.185 2.711-4.95 3.781-.598.363-1.246-.052-1.361-.695l-.014-.098-.183-3.1a6.247 6.247 0 00-4.021 1.58l-.214.194-.104.09-.199.166-.097.077-.186.138a3.8 3.8 0 01-.176.118l-.166.096C.931 13.845.5 13.479.5 11.837c0-3.82 2.62-7.221 6.162-7.736l.21-.027.18-3.09zm1.542 1.279l-.156 3.012a.45.45 0 01-.11.276.394.394 0 01-.25.132l-1.1.123c-2.474.318-4.438 2.397-4.802 5.114a7.706 7.706 0 014.146-1.659l.32-.02 1.377-.031a.388.388 0 01.283.115.448.448 0 01.128.294l.16 3.12c1.294-.885 2.527-1.905 3.765-2.993.8-.713 1.57-1.465 2.307-2.251l-.208-.222-.45-.463a39.813 39.813 0 00-2.53-2.336 37.46 37.46 0 00-2.88-2.211z"
                                            ></path>
                                          </svg>
                                        </Button>
                                      </OverlayTrigger>
                                    )}
                                  </div>
                                </div>
                                <div className="news-info-body-sec">
                                  <h4 onClick={()=> navigate(`/news/${news.news_feed_unique_id}`)}>{news.title}</h4>
                                  <div className="news-category-list-item">
                                    <div className="news-info-publish-date-sec">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 21 21"
                                      >
                                        <path
                                          fill="#676767"
                                          stroke="#676767"
                                          strokeLinecap="round"
                                          strokeMiterlimit="10"
                                          strokeWidth="0.01"
                                          d="M6.5 11.5a1 1 0 100-2 1 1 0 000 2zM14.5 11.5a1 1 0 100-2 1 1 0 000 2zM10.5 11.5a1 1 0 100-2 1 1 0 000 2zM6.5 15.5a1 1 0 100-2 1 1 0 000 2zM14.5 15.5a1 1 0 100-2 1 1 0 000 2zM10.5 15.5a1 1 0 100-2 1 1 0 000 2z"
                                        ></path>
                                        <path
                                          stroke="#676767"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M6.5 1.5v4M14.5 1.5v4M14.5 3.5h-8a5 5 0 00-5 5v6a5 5 0 005 5h8a5 5 0 005-5v-6a5 5 0 00-5-5z"
                                        ></path>
                                      </svg>
                                      <span>
                                        {calculateDays(news.publish_time)} days
                                      </span>
                                    </div>
                                    {/* <div className="news-category-list-1">
                                  {news.categories.length > 0 && news.categories.map((category, key) => (
                                    <h4 className="category-title" key={key}>{category.name}</h4>
                                  ))}
                                  </div> */}
                                    <div className="news-list-action-btn-sec news-list-action-btn-sec-website-hide">
                                      <OverlayTrigger
                                        placement="top"
                                        rootClose
                                        overlay={
                                          <Tooltip id="tooltip">
                                            <strong>
                                              Remove from Bookmarks!
                                            </strong>
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="link"
                                          className="news-bookmark-icon-sec"
                                          onClick={() =>
                                            handleBookmark(news.news_feed_id)
                                          }
                                          disabled={
                                            bookmarkSave.buttonDisable &&
                                            news.news_feed_id ==
                                              bookmarkSave.data.news_feed_id
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="none"
                                            viewBox="0 0 15 18"
                                          >
                                            <path
                                              fill="url(#paint0_linear_222_373)"
                                              stroke="#000"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M4.5 1h6a3.999 3.999 0 014 4.002v10.204a1.802 1.802 0 01-2.55 1.63l-3.62-1.65a2 2 0 00-1.66 0l-3.62 1.65a1.799 1.799 0 01-2.55-1.63V5.002A4.002 4.002 0 014.5 1z"
                                            ></path>
                                            <defs>
                                              <linearGradient
                                                id="paint0_linear_222_373"
                                                x1="1.411"
                                                x2="14.825"
                                                y1="5.509"
                                                y2="6.138"
                                                gradientUnits="userSpaceOnUse"
                                              >
                                                <stop stopColor="#9747FF"></stop>
                                                <stop
                                                  offset="1"
                                                  stopColor="#2F50FE"
                                                ></stop>
                                              </linearGradient>
                                            </defs>
                                          </svg>
                                        </Button>
                                      </OverlayTrigger>
                                      {news.external_link && (
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="bottom"
                                          rootClose
                                          overlay={popoverBottom(news)}
                                        >
                                          <Button className="news-share-btn">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              fill="none"
                                              viewBox="0 0 17 15"
                                            >
                                              <path
                                                fill="#000"
                                                d="M7.051.984c.062-.69.732-1.258 1.407-.843 1.405.884 2.731 1.891 4.013 2.968 1.583 1.343 2.853 2.609 3.752 3.62.39.44.354 1.134-.02 1.554-.89.99-1.832 1.925-2.821 2.801-1.565 1.377-3.185 2.711-4.95 3.781-.598.363-1.246-.052-1.361-.695l-.014-.098-.183-3.1a6.247 6.247 0 00-4.021 1.58l-.214.194-.104.09-.199.166-.097.077-.186.138a3.8 3.8 0 01-.176.118l-.166.096C.931 13.845.5 13.479.5 11.837c0-3.82 2.62-7.221 6.162-7.736l.21-.027.18-3.09zm1.542 1.279l-.156 3.012a.45.45 0 01-.11.276.394.394 0 01-.25.132l-1.1.123c-2.474.318-4.438 2.397-4.802 5.114a7.706 7.706 0 014.146-1.659l.32-.02 1.377-.031a.388.388 0 01.283.115.448.448 0 01.128.294l.16 3.12c1.294-.885 2.527-1.905 3.765-2.993.8-.713 1.57-1.465 2.307-2.251l-.208-.222-.45-.463a39.813 39.813 0 00-2.53-2.336 37.46 37.46 0 00-2.88-2.211z"
                                              ></path>
                                            </svg>
                                          </Button>
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  </div>
                                  <div className="news-info-desc">
                                    <p>{news.description}</p>
                                  </div>
                                  {news.external_link && (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={news.external_link}
                                    >
                                      Continue reading...
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                  {(bookmarksFeed.data.news.length < bookmarksFeed.data.total_news) ? loading ? [...Array(3)].map((i, key) => ( <div className="news-list-body-sec" key={key}>
                    <div className="news-list-box">
                      <div className="news-list-link">
                        <div className="news-list-card">
                          <div className="news-list-left-sec">
                            <div className="news-list-img-sec-1">
                              <Skeleton width="100%" height={320} />
                            </div>
                          </div>
                          <div className="news-list-right-sec">
                            <div className="news-list-skeleton-sec">
                              <Skeleton height={20}/>
                              <div className="news-info-body-sec">
                                <div className="news-info-publish-date-sec mb-3">
                                  <Skeleton width={120} height={20} />
                                </div>
                                <div className="news-info-desc">
                                  <Skeleton count={5} height={10} />
                                </div>
                                <Skeleton width={120} height={40} className="mt-3" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)) : <div className="load-more-sec">
                    <button className="default-btn btn btn-primary" onClick={fetchMoreData}>
                      Load More
                    </button>
                  </div> : null}
                </div>
              </InfiniteScroll>
            )}
          </Container>
        ) : null}
      </div>
    </>
  );
};

export default NewsListIndex;
