import React, { useEffect, useRef, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  NavLink,
  NavbarBrand,
} from "react-bootstrap";
import ReactLanguageSelect from "react-languages-select";
import "react-languages-select/css/react-languages-select.css";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Field, Form as FORM, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {setSelectedCategory} from "../../../store/slices/HomeSlice"
import NewsCategoriesListIndex from "../../LandingPage/NewsCatgeoriesListIndex";

const HeaderIndex = (props) => {

  const [sidebar, setSidebar] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const formRef = useRef(null);
  const selectedCategory = useSelector((state) => state.home.selectedCategory);
  const [showCategory, setShowCategory] = useState(false);

  useEffect(()=> {
    if(!searchParams.get("search_key"))
    formRef?.current?.setFieldValue("search_key", "")
  }, [searchParams])

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return "Good morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      setSidebar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  
  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand style={{ cursor:"pointer" }} onClick={()=> { 
                navigate("/")
                dispatch(setSelectedCategory({
                    label: "All",
                    value: ""
                }))
              }}>
              <Image
                className="logo"
                src={window.location.origin + "/assets/img/logo.png"}
              />
              <div className="logo-tab-sec">
                <h4>Sentient Matters</h4>
                <p>Your one-stop news for all things AI.</p>
              </div>
            </Navbar.Brand>
            <div className="mobile-flex">
              <div className="header-sidebar-collapse-sec webiste-hide">
                <Button
                  className="sidebar-collapse-btn"
                  onClick={() => setSidebar(!sidebar)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="#000"
                    viewBox="0 0 24 24"
                  >
                    <path d="M1 4.75h2.736a3.728 3.728 0 007.195 0H23a1 1 0 000-2H10.931a3.728 3.728 0 00-7.195 0H1a1 1 0 000 2zM7.333 2a1.75 1.75 0 11-1.75 1.75A1.752 1.752 0 017.333 2zM23 11h-2.736a3.727 3.727 0 00-7.194 0H1a1 1 0 000 2h12.07a3.727 3.727 0 007.194 0H23a1 1 0 000-2zm-6.333 2.75a1.75 1.75 0 111.75-1.75 1.752 1.752 0 01-1.75 1.75zM23 19.25H10.931a3.728 3.728 0 00-7.195 0H1a1 1 0 000 2h2.736a3.728 3.728 0 007.195 0H23a1 1 0 000-2zM7.333 22a1.75 1.75 0 111.75-1.75A1.753 1.753 0 017.333 22z"></path>
                  </svg>
                </Button>
                <Button className="sidebar-collapse-btn" aria-controls="basic-navbar-nav" onClick={()=> setShowCategory(!showCategory)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  fill="none"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#000"
                    d="M277.334 384H106.667c-12.8 0-21.333-8.533-21.333-21.333 0-12.8 8.533-21.334 21.333-21.334h170.667c12.8 0 21.333 8.534 21.333 21.334 0 12.8-8.533 21.333-21.333 21.333zM405.334 277.333H106.667c-12.8 0-21.333-8.533-21.333-21.333 0-12.8 8.533-21.333 21.333-21.333h298.667c12.8 0 21.333 8.533 21.333 21.333 0 12.8-8.533 21.333-21.333 21.333zM405.334 170.667H234.667c-12.8 0-21.333-8.534-21.333-21.334 0-12.8 8.533-21.333 21.333-21.333h170.667c12.8 0 21.333 8.533 21.333 21.333 0 12.8-8.533 21.334-21.333 21.334z"
                  ></path>
                </svg>
              </Button>
              </div>
            </div>
            {!location.pathname.includes("/unsubscribe") ? <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <div className="header-right-sec">
                  {/* <div className="header-language-select-sec">
                    <ReactLanguageSelect
                      searchable={false}
                      placeholder={
                        <div className="language-filter">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 18 19"
                          >
                            <path
                              stroke="#000"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M.77 9.5a8.125 8.125 0 1016.25 0 8.125 8.125 0 00-16.25 0z"
                            ></path>
                            <path
                              stroke="#000"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M9.707 1.416s2.438 3.209 2.438 8.084-2.438 8.084-2.438 8.084m-1.625 0S5.645 14.375 5.645 9.5s2.437-8.084 2.437-8.084m-6.8 10.928h15.226M1.282 6.656h15.226"
                            ></path>
                          </svg>
                          <span>Language</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="7"
                            fill="none"
                            viewBox="0 0 11 7"
                          >
                            <path
                              stroke="#5D5F5F"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M1.02 1.637l4.618 3.862 4.378-4.134"
                            ></path>
                          </svg>
                        </div>
                      }
                      languages={["en", "fr", "de", "it", "es"]}
                      className="menu-languages"
                    />
                  </div> */}
                  <div className="header-search-sec mobile-display-none">
                    <Formik
                      initialValues={{
                        search_key: searchParams.get("search_key")
                          ? searchParams.get("search_key")
                          : "",
                      }}
                      innerRef={formRef}
                      onSubmit={(values) => values.search_key && navigate(`/feed/${selectedCategory.label.toLowerCase()}?search_key=${values.search_key}`)}
                    >
                      {({ values, setFieldValue, resetForm }) => (
                        <FORM className="form">
                          <InputGroup className="mb-0">
                            <Field
                              placeholder="Search..."
                              type="text"
                              className="form-control"
                              name="search_key"
                            />
                              {searchParams.get("search_key") && (
                                <InputGroup.Text className="search-clear-btn" id="basic-addon2">
                                <button
                                  type="button"
                                  className="search-btn"
                                  onClick={() => {
                                    setFieldValue("search_key", "");
                                    navigate(`/feed/${selectedCategory.label.toLowerCase()}`);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="#3E4351"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M16.707 8.707L13.414 12l3.293 3.293-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12s4.486 10 10 10 10-4.486 10-10z"></path>
                                  </svg>
                                </button>
                                </InputGroup.Text>
                              )}
                            <InputGroup.Text id="basic-addon2">
                              <button className="search-btn" type="submit">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 23 23"
                                >
                                  <path
                                    stroke="#01151F"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M16.22 16.083l3.666 3.667M10.72 17.917a7.333 7.333 0 100-14.667 7.333 7.333 0 000 14.667z"
                                  ></path>
                                </svg>
                              </button>
                            </InputGroup.Text>
                          </InputGroup>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                  {/* <div className="header-notify-sec">
                    <Button className="notify-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="#303037"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 20a2.496 2.496 0 01-3.14.725A2.114 2.114 0 0110 20M18 9v1a3 3 0 00.66 1.87l.77 1c.353.438.553.978.57 1.54A2.59 2.59 0 0117.41 17H6.59A2.59 2.59 0 014 14.41v-.34c0-.687.274-1.345.76-1.83l.36-.36A3 3 0 006 9.76V9a6 6 0 1112 0z"
                        ></path>
                      </svg>
                    </Button>
                  </div> */}
                  <div className="website-hide news-category-mobile-sidebar">
                    <NewsCategoriesListIndex/>
                  </div>
                  <div className="header-sidebar-collapse-sec mobile-display-none">
                    <Button
                      className="sidebar-collapse-btn"
                      onClick={() => setSidebar(!sidebar)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="#000"
                        viewBox="0 0 24 24"
                      >
                        <path d="M1 4.75h2.736a3.728 3.728 0 007.195 0H23a1 1 0 000-2H10.931a3.728 3.728 0 00-7.195 0H1a1 1 0 000 2zM7.333 2a1.75 1.75 0 11-1.75 1.75A1.752 1.752 0 017.333 2zM23 11h-2.736a3.727 3.727 0 00-7.194 0H1a1 1 0 000 2h12.07a3.727 3.727 0 007.194 0H23a1 1 0 000-2zm-6.333 2.75a1.75 1.75 0 111.75-1.75 1.752 1.752 0 01-1.75 1.75zM23 19.25H10.931a3.728 3.728 0 00-7.195 0H1a1 1 0 000 2h2.736a3.728 3.728 0 007.195 0H23a1 1 0 000-2zM7.333 22a1.75 1.75 0 111.75-1.75A1.753 1.753 0 017.333 22z"></path>
                      </svg>
                    </Button>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse> : null}
          </Container>
        </Navbar>
        {sidebar && (
          <div className="drawer">
            <div className="drawer-overlay" onClick={()=> setSidebar(!sidebar)}></div>
            <div className="drawer-wrapper">
              <div className="drawer-header">
                <div className="drawer-close-btn-sec">
                  <Button
                    className="drawer-close-btn"
                    onClick={() => setSidebar(!sidebar)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#5B5B5B"
                        d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.997.997 0 005.7 7.11L10.59 12 5.7 16.89a.997.997 0 001.41 1.41L12 13.41l4.89 4.89a.997.997 0 001.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                      ></path>
                    </svg>
                  </Button>
                </div>
                <div className="drawer-header-info">
                  <Image
                    className="drawer-header-info-img"
                    src={
                      window.location.origin +
                      "/assets/img/drawer/drawer-header-img.png"
                    }
                  />
                  <h4>{getGreeting()}, Dude!</h4>
                </div>
              </div>
              <div className="drawer-apperance-sec">
                <h4>Appearance</h4>
                <div className="drawer-apperance-box">
                  <div className="drawer-apperance-item">
                    <label
                      for="radio-card-1"
                      class="radio-card"
                      onClick={() => props.setTheme("light")}
                    >
                      <input
                        type="radio"
                        checked={props.theme == "light"}
                        name="radio-card"
                        id="radio-card-1"
                      />
                      <div class="card-content-wrapper">
                        <span class="check-icon"></span>
                        <div class="card-content">
                          <Image
                            className="drawer-card-content-apperance-icon"
                            src={
                              window.location.origin +
                              "/assets/img/drawer/light-mode-icon.png"
                            }
                          />
                        </div>
                      </div>
                    </label>
                    <div className="card-content-desc">
                      <p>Light</p>
                    </div>
                  </div>
                  <div className="drawer-apperance-item">
                    <label
                      for="radio-card-2"
                      class="radio-card"
                      onClick={() => props.setTheme("dark")}
                    >
                      <input
                        type="radio"
                        checked={props.theme == "dark"}
                        name="radio-card"
                        id="radio-card-2"
                      />
                      <div class="card-content-wrapper">
                        <span class="check-icon"></span>
                        <div class="card-content">
                          <Image
                            className="drawer-card-content-apperance-icon"
                            src={
                              window.location.origin +
                              "/assets/img/drawer/dark-mode-icon.png"
                            }
                          />
                        </div>
                      </div>
                    </label>
                    <div className="card-content-desc">
                      <p>Dark</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="drawer-content">
                <ul className="list-unstyled drawer-page-link-list" onClick={()=> setSidebar(false)}>
                  {/* <li>
                    <Link to="#">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 18 21"
                        >
                          <path
                            stroke="#303037"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M11 18.041a2.496 2.496 0 01-3.14.724 2.114 2.114 0 01-.86-.724M15 7.041v1a3 3 0 00.66 1.87l.77 1c.353.438.553.979.57 1.54a2.59 2.59 0 01-2.59 2.59H3.59A2.59 2.59 0 011 12.451v-.34c0-.686.274-1.344.76-1.83l.36-.36A3 3 0 003 7.801v-.76a6 6 0 1112 0z"
                          ></path>
                        </svg>
                      <span>Notification Preference</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/feed/bookmarks" onClick={()=> dispatch(setSelectedCategory({
                      label: "Bookmarks",
                      value: 0
                    }))}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 16 19"
                      >
                        <path
                          stroke="#303037"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 1.041h6a3.999 3.999 0 014 4.002v10.204a1.801 1.801 0 01-2.55 1.63l-3.62-1.65a2 2 0 00-1.66 0l-3.62 1.65A1.8 1.8 0 011 15.248V5.043a4.002 4.002 0 014-4.002z"
                        ></path>
                      </svg>
                      <span>Bookmarks</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/customize">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 19 18"
                        >
                          <path
                            stroke="#000"
                            strokeWidth="2"
                            d="M8.549 1.969c.3-.922 1.603-.922 1.902 0l1.182 3.637a1 1 0 00.95.69h3.825c.97 0 1.372 1.24.588 1.81l-3.094 2.248a1 1 0 00-.363 1.118l1.182 3.637c.299.921-.755 1.688-1.54 1.118l-3.093-2.248a1 1 0 00-1.176 0l-3.094 2.248c-.784.57-1.838-.197-1.539-1.118l1.182-3.637a1 1 0 00-.363-1.118L2.004 8.106c-.784-.57-.381-1.81.588-1.81h3.824a1 1 0 00.951-.69L8.55 1.969z"
                          ></path>
                      </svg>
                      <span>Customize</span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="#">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 19 18"
                        >
                          <path
                            stroke="#000"
                            strokeWidth="2"
                            d="M8.549 1.969c.3-.922 1.603-.922 1.902 0l1.182 3.637a1 1 0 00.95.69h3.825c.97 0 1.372 1.24.588 1.81l-3.094 2.248a1 1 0 00-.363 1.118l1.182 3.637c.299.921-.755 1.688-1.54 1.118l-3.093-2.248a1 1 0 00-1.176 0l-3.094 2.248c-.784.57-1.838-.197-1.539-1.118l1.182-3.637a1 1 0 00-.363-1.118L2.004 8.106c-.784-.57-.381-1.81.588-1.81h3.824a1 1 0 00.951-.69L8.55 1.969z"
                          ></path>
                      </svg>
                      <span>Feedback</span>
                    </Link>
                  </li> */}
                  <li>
                    <a href="/page/privacy" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 16 21"
                      >
                        <path
                          stroke="#303037"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M11 3.041h.5a3.5 3.5 0 013.5 3.5v9a3.5 3.5 0 01-3.5 3.5h-7a3.5 3.5 0 01-3.5-3.5v-9a3.5 3.5 0 013.5-3.5h.45M6 10.041h4M6 14.041h3"
                        ></path>
                        <path
                          stroke="#303037"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 1.041H7a2 2 0 100 4h2a2 2 0 100-4z"
                        ></path>
                      </svg>
                      <span>Privacy Policy</span>
                    </a>
                  </li>
                  <li>
                    <a href="/page/terms" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 17 21"
                      >
                        <path
                          stroke="#303037"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M16 15.041v-10c0-2.209-1.492-4-3.333-4H4.333c-1.84 0-3.333 1.791-3.333 4v10c0 2.21 1.493 4 3.333 4h8.334c1.84 0 3.333-1.79 3.333-4zM12 10.041H5M10 6.041H5M8 14.041H5"
                        ></path>
                      </svg>
                      <span>Terms & Conditions</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </header>
      {showCategory && <div className="responsive-category-show-sec">
        <div className="new-category-list-overlay" onClick={()=> setShowCategory(!showCategory)}></div>
        <NewsCategoriesListIndex showCategory={showCategory} setShowCategory={setShowCategory} />
        </div>}
    </>
  );
};

export default HeaderIndex;
