import axios from "axios";
import { apiConstants } from "./components/Constant/constant";
import {
  isAndroid,
  isIOS,
  isWindows,
  isMacOs,
  mobileModel,
  browserName,
  deviceDetect,
  deviceType,
  osName,
  mobileVendor,
  browserVersion,
} from "react-device-detect";

export const apiUrl = `https://nquyiasxc8zk.sentientmatters.com/api/user/`;

const Environment = {
  postMethod: async (action, object, baseUrl = apiUrl) => {
    const url = baseUrl + action;

    let formData = new FormData();
    // append your data
    for (var key in object) formData.append(key, object[key]);

    var device_type = "";
    var device_model = "";
    
    if (isAndroid) {
      device_type = "android";
      device_model = mobileModel;
    } else if (isIOS) {
      device_type = "ios";
      device_model = mobileModel;
    } else {
      device_type = "web";
      device_model = deviceDetect(window.navigator.userAgent).osName;
    }
    
    formData.append("device_type", device_type);
    formData.append("device_model", device_model);
    formData.append("browser_type", browserName);
    formData.append(
      "timezone",
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    const accessToken = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : sessionStorage.getItem("token")
      ? sessionStorage.getItem("token")
      : "";
    let config = { headers: { Authorization: "Bearer " + accessToken } };
    return await axios.post(url, formData, config);
  },
};

export default Environment;
