import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { categoriesListStart } from "../../store/slices/SettingsSlice";
import Skeleton from 'react-loading-skeleton'
import { setSelectedCategory } from "../../store/slices/HomeSlice";
import Select from "react-select";
import {
  isMobile,
} from "react-device-detect";

const NewsCategoriesListIndex = (props) => {

  const dispatch = useDispatch();
  const categoriesList = useSelector((state) => state.settings.categoriesList);
  const selectedCategory = useSelector((state) => state.home.selectedCategory);
  const [extraCategories, setExtraCategories] = useState([]);
  const [dropdownValue, setDropdownValue] = useState(null);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: "max-width",
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "0!important",
      borderRadius: "4px !important",
      boxShadow: "none!important",
      height: "40px",
      minWidth: "150px",
      cursor:"pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000F",
      fontSize: "0.9em",
      fontWeight: "500",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
      fill: "#32089F!important",

    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#000",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        ":hover": {
          backgroundColor: "#f7f7f7",
        },
      };
    },
  };

  useEffect(()=> {
    dispatch(categoriesListStart());
  }, [])

  const handleCategoryChange = (category) => {
    if(category) {
      dispatch(setSelectedCategory({
        label: category.name,
        value: category.category_id,
      }))
    }
  }

  useEffect(()=> {
    if (!categoriesList.loading && Object.keys(categoriesList.data).length > 0 && categoriesList.data.categories.length > 0) {
        let categories = categoriesList.data.categories.slice(10).map((category)=> ({
            label: category.name,
            value: category.category_id,
        }))
        setExtraCategories(categories);
    }
  }, [categoriesList])

  return (
    <>
      <div className="news-category-list-sec">
        <Container>
          <ul className="list-unstyled news-category-list" onClick={()=> props.setShowCategory && props.setShowCategory(!props.showCategory)}>
            {categoriesList.loading ? (
                [...Array(10)].map((i, key) => (
                  <li key={key}>
                    <Skeleton height={30} width={isMobile ? 150 : 80} className="my-3" />
                  </li>
                ))
            ) : Object.keys(categoriesList.data).length > 0 ? (
              <React.Fragment>
                <li>
                    <Link
                      to={`/`}
                      className={
                        selectedCategory.label == "All"
                          ? "active"
                          : ""
                      }
                      onClick={()=> handleCategoryChange({
                        name: "All",
                        category_id: ""
                      })}
                    >
                      All
                    </Link>
                  </li>
                {!isMobile && categoriesList.data.categories.slice(0,10).map((category, key) => (
                  <li key={key}>
                    <Link
                      to={`/feed/${category.name.toLowerCase()}`}
                      onClick={()=> {
                        setDropdownValue(null)
                        handleCategoryChange(category)
                      }}
                      className={
                        selectedCategory.label == category.name
                          ? "active"
                          : ""
                      }
                    >
                      {category.name}
                    </Link>
                  </li>
                ))}
                {isMobile && categoriesList.data.categories.map((category, key) => (
                  <li key={key}>
                    <Link
                      to={`/feed/${category.name.toLowerCase()}`}
                      onClick={()=> {
                        setDropdownValue(null)
                        handleCategoryChange(category)
                      }}
                      className={
                        selectedCategory.label == category.name
                          ? "active"
                          : ""
                      }
                    >
                      {category.name}
                    </Link>
                  </li>
                ))}
                {!isMobile && Object.keys(extraCategories).length > 0 && <li className="header-dropdown-sec">
                  <Select
                    options={extraCategories}
                    styles={customStyles}
                    isSearchable={false}
                    placeholder="More"
                    onChange={(option) => {
                      handleCategoryChange({name: option.label,category_id: option.value})
                      setDropdownValue(option);
                    }}
                    value={dropdownValue}
                  />
                </li>}
              </React.Fragment>
            ) : null}
          </ul>
        </Container>
      </div>
    </>
  );
};

export default NewsCategoriesListIndex;
